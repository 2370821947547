.body_overflow_hide {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.Custom_Slider_main_section {
  background: #02020a;
}
.Custom_Slider_main_section img {
  position: relative;
  background: #02020a;
}
.Custom_Slider_main_section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
  width: 100%;
}

.Custom_Slider_main_section .carousel-indicators [data-bs-target] {
  height: 5px !important;
  width: 20px !important;
  margin-left: 6px !important;
}
.Custom_Slider_main_section .carousel-indicators .active {
  height: 5px !important;
  width: 35px !important;
}
/* =========OneBattery_main_headings======== */
.OneBattery_main_section {
  padding: 73px 0 0 0;
  background: #02020a;
}

.OneBattery_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.OneBattery_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  margin-top: 13px;
  margin-bottom: 56px;
}

.OneBattery_single_box h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin: 16px 0;
}

.OneBattery_single_box p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 0;
}

.custom_hero_main_section {
  padding-top: 120px;
  background: #02020a;
  position: relative;
  overflow: hidden;
}
.box_ring {
  position: absolute;
  right: 41px;
  top: 34px;
  width: 168px;
  height: 168px;
}
.custom_hero_main_section .banner_img {
  position: relative;
  z-index: 9;
  border-radius: 32px;
}
.custom_hero_main_section .box {
  width: 1277px;
  height: auto;
}

.custom_hero_main_section .box {
  width: 90%;
  height: 100%;
  margin: auto;
}
.custom_hero_main_section .box video {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 32px;
}
/* =========solution_tabs_main_section======== */
.solution_tabs_main_section {
  padding-top: 120px;
  background: #02020a;
}
.solution_tabs_main_section .next_btn {
  content: '';
  background-image: url('../imgs/next-arrow.webp') !important;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-color: none;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  background-position: center !important;

  background: #1f1f1f;
  border-radius: 119.892px;
  border: none;
  outline: none;
  box-shadow: none;
}
.solution_tabs_main_section .back_btn {
  content: '';
  background-image: url('../imgs/back-arrow.webp') !important;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-color: none;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-position: center !important;
  background: transparent;
  border-radius: 119.892px;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.back_btn:disabled {
  opacity: 0.3 !important;
}
.next_btn:disabled {
  opacity: 0.3 !important;
}
.solution_tabs_btns_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_tabs_btns_main_div button {
  box-shadow: none;
  outline: none;
  border: none;
  background: none;
  margin-right: 92px;
  position: relative;
  z-index: 99;
}

.solution_tabs_btns_main_div button a {
  text-decoration: none;
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  padding: 8px 16px;
  transition: 0.3s all ease-in-out;
}
.solution_tabs_btns_main_div button a:hover {
  color: #17e884;
}

.actives {
  color: #17e884 !important;
  border: 1px solid #17e884 !important;
  border-radius: 72px;
  padding: 8px 16px;
}
.actives a {
  color: #17e884 !important;
}
.fleet_main_section {
  padding: 120px 0 109px 0;
  position: relative;
}
.custom_fade_bg {
  position: absolute;
  left: -150px;
  top: -300px;
  width: 702px;
}
.custom_arrow {
  position: absolute;
  left: -10px;
  top: 60px;
  width: 123.46px;
}
.fleet_main_section_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 13px;
}

.fleet_main_section_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  margin-bottom: 0;
  width: 50%;
  margin: auto;
  padding-bottom: 36px;
}

.fleet_main_section .carousel-control-next,
.fleet_main_section .carousel-control-prev {
  display: flex !important;
  display: flex !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #1f1f1f;
  opacity: 1 !important;
}
.fleet_main_section .carousel-indicators {
  display: none !important;
}
.fleet_main_section .carousel-control-next {
  right: 9%;
  top: auto;
  bottom: 13% !important;
  z-index: 999;
}
.fleet_main_section .carousel-control-prev {
  right: 33%;
  top: auto;
  left: auto;
  bottom: 13% !important;
  z-index: 999;
}
.fleet_main_section .carousel-control-next-icon,
.fleet_main_section .carousel-control-prev-icon {
  width: 16px;
  height: 16px;
}
.fleet_main_section .carousel-caption {
  width: 35%;
  right: 56px !important;
  left: auto !important;
  bottom: 31px !important;
  padding: 50.3548px 37.1667px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(14.3871px);
  border-radius: 38.3656px;
  text-align: left !important;
  min-height: 380px;
}
.OEM_section_main .fleet_main_section .carousel-caption {
  padding-bottom: 124px;
  min-height: 460px;
}
.carousel_caption h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin-bottom: 16px;
}
.carousel_caption p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #02020a;
  opacity: 0.6;
}

.graph_table_section_main {
  padding-top: 120px;
}
.graph_table_first_col_content_side {
  padding-right: 50px;
}
.graph_table_first_col_content_side h1 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 24px;
}
.graph_table_first_col_content_side .main_para {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 18px;

  border-bottom: 1.2px solid rgba(187, 187, 188, 0.6);
}
.graph_table_first_col_content_side .main_para p {
  width: 75%;
}
.first_col_content_side_bottom_single {
  display: flex;
  align-items: center;
}

.first_col_content_side_bottom_single h3 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  width: 30%;
  margin-right: 40px;
}
.first_col_content_side_bottom_single p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 0;
  width: 75%;
}

.first_col_content_side_bottom_single .color_green {
  color: #17e884;
}
.first_col_content_side_bottom_single .color_red {
  color: #eb5757;
}
.border_top_bottom {
  border-top: 1px solid rgba(187, 187, 188, 0.2);
  border-bottom: 1px solid rgba(187, 187, 188, 0.2);
  padding: 14px 0;
  margin: 14px 0;
}

.fleet_form_section_main {
  padding-top: 120px;
}
.fleet_form_section_main_headings h4 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 21px;
}

.fleet_form_section_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  width: 55%;
  margin-bottom: 36px;
}
.fleet_form_section_main .Battery_Form_main_section {
  padding-bottom: 0;
}
.OEM_section_main {
  background: #02020a;
  position: relative;
}
.OEM_section_main .fleet_main_section {
  padding-bottom: 202px;
}
.OEM_section_main .fleet_main_section {
  padding-top: 0;
}
.e_auto_main_section {
  position: relative;
}
.e_auto_bg {
  position: absolute;
  bottom: -20px;
  z-index: 9;
  left: 0;
  width: 286.56px;
}
.eauto_bg {
  position: absolute;
  right: 0;
  top: -250px;
}

.e_auto_main_section .fleet_main_section {
  padding-top: 0;
}
.fleet_graph_div_main {
  position: relative;
}
.fleet_graph_hrs_div_main {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
}
.fleet_graph_hrs_div_single {
  margin-bottom: 71px;
  border-top: 1px solid rgba(187, 187, 188, 0.2);
}

.fleet_graph_hrs_div_single h4 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  background: #02020a;
  display: table;
  margin-top: -15px !important;
  padding-right: 15px;
}

.graph_indicators_main {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 33px;
}
.graph_indicators_single {
  display: flex;
}
.graph_indicators_single .graph_dot {
  width: 20px;
  height: 20px;
  background: #21848f;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 16px;
}
.graph_indicators_single .graph_dot_2 {
  background: #0c354c;
}
.graph_indicators_single h5 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}
.fleet_graph_cols_div_main {
  position: absolute;
  top: 14%;
  left: 15%;
  display: flex;
  justify-content: space-between;
  width: 76%;
}
.fleet_graph_cols_div_single {
  position: relative;
  width: 135px;
  height: 349px;
  background: linear-gradient(180deg, #2eb2a6 0%, #1c7487 100%);
  border-radius: 4px 4px 0px 4px;
  padding: 16px 15px 10px 15px;
}

.fleet_graph_cols_div_single h3 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #252527;
  margin-bottom: 0;
  margin-top: 6px;
  position: relative;
  z-index: 9;
}
.fleet_graph_cols_div_single h2 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #252527;
  position: relative;
  z-index: 9;
}
.fleet_graph_cols_div_single_bottom_text {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin-top: 39px;
  text-align: center;
}
.fleet_graph_cols_div_single .indicator_img {
  position: relative;
  z-index: 9;
}
.fleet_graph_cols_div_single_bg_change {
  width: 135px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  background: #0c354c;
  z-index: 1;
}

.indicator_big_img {
  position: absolute;
  bottom: 10px;
  width: 79.1px;
  height: 103px;
  left: 50%;
  transform: translate(-50%);
}

.eauto_table_main_headings {
  text-align: center;
}

.eauto_table_main_headings h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 16px;
}
.eauto_table_main_headings .para_white {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-bottom: 42px;
}

.eauto_table_main_headings .para_blue {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0264ff;
  margin-bottom: 20px;
}
.scroll_content_gap_left {
  margin-left: 70px;
}
.e_auto_graph_first_col_div {
  border-bottom: 1.5px solid rgba(187, 187, 188, 0.2);
}
.e_auto_graph_first_col_div:last-child {
  border-bottom: 0;
}

.e_auto_graph_first_col_div h2 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  color: #fcfcfc;
  padding-bottom: 16px;
  padding-top: 16px;
}
.pb_0 {
  padding-bottom: 0 !important;
}
.pt_0 {
  padding-top: 0 !important;
}
.e_auto_graph_other_cols {
  padding: 24px;
  min-height: 550px;

  border-radius: 20px;
}
.e_auto_graph_other_cols_bg_one {
  background-color: rgb(32 32 38);
}
.e_auto_graph_other_cols_border {
  background: linear-gradient(to right, #0264ff, #17e884);

  padding: 2px;
  border-radius: 20px;
}
.e_auto_graph_other_cols .headings {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #fcfcfc;
  margin-bottom: 12px;
  min-height: 45px;
}
.e_auto_graph_other_cols .auto_img {
  width: 130px;
  height: 80px;
  margin: auto;
  display: flex;
  margin-bottom: 24px;
}
.e_auto_graph_small_box h4 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #fcfcfc;
  margin-bottom: 4px;
}
.e_auto_graph_small_box h5 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: initial;
  letter-spacing: 0.02em;
  color: #bbbbbc;
  text-align: right;
}
.border_top_bottoms {
  border-top: 1px solid rgba(187, 187, 188, 0.15);
  /* border-bottom: 1px solid rgba(187, 187, 188, 0.15); */
  padding: 16px 0;
}
.border_tops {
  border-bottom: 1px solid rgba(187, 187, 188, 0.15);
}
.mb_00 {
  margin-bottom: 0;
}

.e_auto_graph_other_cols_border_two {
  border: 2px solid rgba(187, 187, 188, 0.08);
  border-radius: 20px;
}
.e_auto_graph_other_cols_bg_two {
  background: rgba(187, 187, 188, 0.08);
}

.e_auto_graph_first_col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.Assumptions_section {
  margin-top: 32px;
}
.Assumptions_section .border_top {
  border-top: 1px solid rgba(187, 187, 188, 0.6);
}
.Assumptions_single_col {
  display: flex;
  align-items: flex-start;
}
.Assumptions_section_headings h1 {
  text-align: center;
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0264ff;
  margin-bottom: 16px;
  margin-top: 10px;
  text-align: left;
}
.Assumptions_single_col h3 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #f1f1fa;
  margin-bottom: 0;
  margin-top: 3px;
}
.high_light_text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
}
.Assumptions_single_col p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #f1f1fa;
}
.Assumptions_single_col img {
  margin-right: 16px;
  max-width: 33px;
  margin-top: 8px;
}
.Engine_icon {
  height: 38px !important;
  max-width: inherit !important;
  width: 38px !important;
}
.Earn_Revenue_mobile {
  display: none;
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 24px;
}
.vehicle_sol_mob_img {
  display: none;
}

.eauto_table_slider_mobile {
  background: #02020a;
  display: none;
}
.eauto_table_slider_mobile .carousel-control-prev,
.eauto_table_slider_mobile .carousel-control-next {
  display: none !important;
}

.eauto_table_slider_mobile .carousel-indicators {
  display: block !important;
}

.Custom_Slider_main_section .carousel-indicators {
  display: none;
}

.white_space_one {
  white-space: nowrap;
}

@media screen and (max-width: 1400px) {
  .e_auto_graph_other_cols {
    min-height: 600px;
  }
}

@media screen and (max-width: 1024px) {
  .fleet_main_section .carousel-caption {
    padding-top: 30px;
    padding-bottom: 100px !important;
  }
  .fleet_main_section .carousel-control-next {
    right: 10%;
  }
  .fleet_main_section .carousel-control-next,
  .fleet_main_section .carousel-control-prev {
    bottom: 12% !important;
  }
  .fleet_graph_cols_div_main {
    width: 82%;
  }
  .graph_table_first_col_content_side {
    padding-right: 0;
  }
  .fleet_main_section_headings p {
    width: 80%;
  }
  .carousel_caption h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .e_auto_graph_other_cols {
    min-height: auto;
  }
  .Custom_Slider_main_section h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    top: 50%;
  }
  .OneBattery_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }
  .OneBattery_main_headings p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .OneBattery_single_box h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 14px 0 12px 0;
  }
  .OneBattery_single_box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .fleet_main_section_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }
  .fleet_main_section_headings p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }
  .fleet_main_section .carousel-caption {
    right: 0px !important;
    left: auto !important;
    bottom: -1px !important;
    top: 100%;
    position: relative;
    width: 100%;
    margin-top: -50px;
    padding: 36px 24px !important;
    padding-bottom: 98px !important;
  }
  .fleet_main_section .carousel-inner {
    overflow: visible;
  }
  .fleet_main_section .carousel-control-prev {
    left: 20px;
  }
  .fleet_main_section .carousel-control-next {
    right: 20px;
  }
  .carousel_caption h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  .carousel_caption p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .graph_table_first_col_content_side h1,
  .Earn_Revenue_mobile {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  .graph_table_first_col_content_side .main_para p {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 36px;
  }
  .fleet_graph_div_main {
    margin-top: 42px;
  }
  .first_col_content_side_bottom_single p {
    width: 100%;
  }
  .fleet_graph_cols_div_single h3,
  .fleet_graph_cols_div_single h2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 140%;
  }
  .fleet_graph_cols_div_single {
    padding: 9px;
  }
  .fleet_graph_cols_div_single_bottom_text {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-top: 19px;
  }
  .fleet_form_section_main_headings p {
    width: 100%;
  }
  .scroll_content {
    overflow: scroll;
    padding-bottom: 20px;
  }
  .scroll_content .row {
    min-width: 1000px;
    overflow: scroll;
  }
  .scroll_content .col-md-2 {
    flex: 0 0 auto;
    width: 18.666667%;
  }
  .e_auto_graph_other_cols {
    padding: 12px;
  }
  .e_auto_bg {
    width: 100px;
    bottom: 0;
  }
  .eauto_bg {
    width: 100px;
    top: -100px;
  }
  .OEM_section_main .fleet_main_section {
    padding-bottom: 128px;
  }
  .Earn_Revenue_desktop {
    display: none;
  }
  .Earn_Revenue_mobile {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .custom_hero_main_section .box {
    width: auto;
    height: auto;
  }
  .eauto_table_slider_mobile {
    display: block;
  }
  .Custom_Slider_main_section h1 {
    position: relative;
    margin-bottom: 0;
    top: auto;
    left: 0;
    transform: translate(0);
    margin-top: 24px;
  }
  .Custom_Slider_main_section .carousel-indicators {
    bottom: -30px;
    display: none;
  }
  .custom_hero_main_section {
    padding-top: 80px;
  }
  .box_ring {
    right: 9px;
    top: 35px;
    width: 109px;
  }
  .OneBattery_single_box {
    margin-top: 36px !important;
  }
  .OneBattery_main_headings p {
    margin-bottom: 0;
  }
  .solution_tabs_btns_main_div button {
    margin-right: 30px;
    margin-left: 30px;
  }
  .custom_arrow {
    width: 50px;
    top: -40px;
  }
  .fleet_main_section,
  .graph_table_section_main {
    padding-top: 80px;
  }
  .fleet_graph_cols_div_single {
    width: 100px;
  }
  .fleet_graph_cols_div_single_bg_change {
    width: 100%;
  }
  .indicator_big_img {
    width: 50px;
    height: auto;
    bottom: -14px;
  }
  .graph_indicators_single h5 {
    font-weight: 700;
    font-size: 8.0315px;
    line-height: 12px;
  }
  .graph_indicators_single .graph_dot {
    width: 10px;
    height: 10px;
  }
  .graph_indicators_main {
    margin-bottom: 16px;
  }
  .fleet_graph_hrs_div_single {
    margin-bottom: 35px;
  }
  .fleet_graph_cols_div_single {
    height: 205px;
  }
  .fleet_graph_cols_div_single_bg_change {
    height: 80px !important;
  }
  .fleet_graph_cols_div_main {
    top: 18%;
    width: 76%;
    left: 20%;
  }
  .fleet_graph_cols_div_single_bg_change_2 {
    height: 120px !important;
  }
  .fleet_form_section_main {
    padding-top: 80px;
  }
  .fleet_form_section_main_headings h4 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  .fleet_form_section_main_headings p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
  .fleet_form_section_main .Battery_Form_main_section {
    padding-top: 0;
  }
  .fleet_main_section {
    padding-bottom: 80px;
  }
  .eauto_table_main_headings h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  .eauto_table_main_headings .para_white {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
  }
  .eauto_table_main_headings .para_blue {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .Assumptions_single_col p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .Assumptions_single_col h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  .solution_tabs_btns_main_div button a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .solution_tabs_btns_main_div button {
    margin-right: 10px;
    margin-left: 10px;
  }
  .custom_fade_bg {
    position: absolute;
    left: -193px;
    top: -140px;
    width: 702px;
  }
  .fleet_main_section .carousel-caption {
    min-height: 325px;
  }
  .OEM_section_main .fleet_main_section .carousel-caption {
    min-height: 325px;
  }
  .scroll_content_gap_left {
    margin-left: 0px;
  }
  .Custom_Slider_main_section .carousel-indicators {
    display: none;
  }
  .Custom_Slider_main_section {
    margin-top: 70px;
  }
  .tabs_slider_main_section img {
    border-radius: 32px;
  }
  .scroll_content {
    display: none;
  }

  .eauto_table_slider_mobile .col-4 {
    padding-left: 0 !important;
    width: 32%;
  }
  .eauto_table_slider_mobile .col-8 {
    padding-left: 0 !important;
  }
  .eauto_table_slider_mobile .col-6 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .eauto_table_slider_mobile .carousel-indicators {
    bottom: -50px;
  }
  .e_auto_graph_small_box h5 {
    margin-bottom: -5px;
  }
  .e_auto_graph_first_col_div h2 {
    margin-bottom: 0;
  }
  .Assumptions_section .border_top {
    margin-top: 32px;
  }
}
@media screen and (max-width: 500px) {
  .fleet_graph_cols_div_single .indicator_img {
    width: 17px;
  }
  .fleet_graph_cols_div_single {
    padding: 0px 4px;
  }
  .vehicle_sol_mob_img {
    display: block;
  }
  .vehicle_sol_desktop_img {
    display: none;
  }
  .fleet_main_section .carousel-control-next,
  .fleet_main_section .carousel-control-prev {
    bottom: 7% !important;
  }
  .box_ring {
    width: 72px;
    right: -5px;
    top: 48px;
  }
  .border_top_bottoms,
  .e_auto_graph_first_col_div h2 {
    padding: 7px 0;
  }
  .border_top_bottoms {
    padding: 13px 0;
  }
  .e_auto_graph_first_col_div h2 {
    padding: 3px 0;
    line-height: 21px;
  }
  .l_h_25 {
    line-height: 23px !important;
  }
  .l_h_18 {
    line-height: 18px !important;
  }
  .l_h_23 {
    line-height: 23px !important;
  }
  .e_auto_graph_small_box h4 {
    font-size: 12px !important;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;
    letter-spacing: 0.096px;
  }
  .e_auto_graph_small_box h5 {
    font-size: 8px;
    font-family: Manrope;
    font-weight: 500;
    letter-spacing: 0.16px;
  }
  .scroll_content .col-md-2 {
    width: 16%;
    padding-left: 0;
  }
  .scroll_content_gap_left .col-2 {
    width: 14%;
  }
  .e_auto_graph_other_cols {
    height: auto;
  }
  .e_auto_graph_other_cols .auto_img {
    width: 84.118px;
    height: 44px;
  }
  .e_auto_graph_other_cols_border {
    padding: 1px;
  }
  .e_auto_graph_other_cols .headings {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  .e_auto_graph_other_cols {
    padding: 9px 5px;
  }
  .pl_1 {
    padding: 0px 1px 0 0 !important;
  }
  .min_h_80 {
    min-height: 80px;
  }
}
@media screen and (max-width: 400px) {
  .fleet_graph_cols_div_single {
    width: 80px;
  }
}

.OneBattery_single_box_img_one {
  width: 317px !important;
}

.OneBattery_single_box_img_two {
  width: 274px !important;
}

.OneBattery_single_box_img_three {
  width: 339px !important;
}
