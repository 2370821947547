.contact_us_form_main_page {
  background: #02020a;
  padding-top: 186px;
  position: relative;
}

.contact_us_form_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
  margin-bottom: 0;
  padding-bottom: 56px;
}

.contact_us_form_main_page .our_footprint_inner_main_box {
  margin-top: 0;
  background: #02020a;
  border-radius: 32px;
  padding: 88px 119px;
}

.our_footprint_inner_main_box_border {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  padding: 2px;
  border-radius: 32px;
  position: relative;
  z-index: 1;
}

.form_bg1 {
  position: absolute;
  left: 0;
  bottom: 50px;
}

.form_bg2 {
  position: absolute;
  right: 0;
  top: 400px;
}

.form_bg_fade3 {
  position: absolute;
  top: 0px;
  right: 0;
}

.form_bg_fade4 {
  position: absolute;
  top: 200px;
  left: -200px;
}

.contact_map_main_section {
  padding: 120px 0 202px 0;
  background: #02020a;
  position: relative;
}

.form_bg_fade5 {
  position: absolute;
  right: 100px;
  top: 0;
}

.form_bg_fade6 {
  position: absolute;
  left: -250px;
  top: -350px;
}

.contact_side_col h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-bottom: 20px;
}

.contact_side_single_col {
  margin-bottom: 16px;
}

.contact_side_col_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_side_single_col h5 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #bbbbbc;
  margin-bottom: 4px;
}

.contact_side_single_col a {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f1f1fa;
  text-decoration: none;
}
.contact_side_single_col a:hover {
  text-decoration: none;
  color: #f1f1fa;
}
.Follow_us_div {
  margin-top: 52px;
  margin-bottom: 12px;
}

.Follow_us_socail_icons img {
  margin-right: 24px;
  width: 32px;
  height: 32px;
}

.contact_map_mob {
  display: none;
}

@media screen and (max-width: 991px) {
  .contact_us_form_main_headings h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    padding-bottom: 42px;
  }

  .contact_us_form_main_page {
    padding-top: 137px;
  }

  .contact_us_form_main_page .our_footprint_inner_main_box {
    padding: 43px 18px;
  }

  .contact_us_form_main_page .our_footprint_inner_main_box h2 {
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  .contact_side_col_main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .contact_map_main_section {
    padding-top: 80px;
    padding-bottom: 141px;
  }

  .contact_side_col h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .contact_side_single_col h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .contact_side_single_col h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .Follow_us_div {
    margin-top: 24px;
    margin-bottom: 8px !important;
  }

  .Follow_us_socail_icons img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .map_col {
    margin-top: 52px !important;
  }
}

@media screen and (max-width: 768px) {
  .form_bg_fade5 {
    top: -100px;
  }

  .form_bg_fade3 {
    top: -64px;
    right: -163px;
  }

  .form_bg_fade4 {
    top: 0;
  }
  .contact_map_mob {
    display: block;
  }
  .contact_map {
    display: none;
  }
  .form_bg1 {
    width: 150px;
  }
}

.input_box option {
  padding: 10px !important;
  margin: 10px !important;
}
