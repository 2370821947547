.Battery_Hero_main_section img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
.Battery_Hero_main_section video {
  transform: scale(1.02);
}
.Advanced_battery_main_section {
  position: relative;
  background: linear-gradient(180deg, #1f1822 0%, #020209 100%);
  padding-bottom: 120px;
}

.battery_bg_arrow {
  position: absolute;
  right: 10px;
  top: 400px;
  width: 200px;
}

.Advanced_battery_main_section_bg {
  position: absolute;
  top: 0;
}

.Advanced_battery_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
  padding-top: 82px;
  padding-bottom: 141px;
  position: relative;
  z-index: 1;
  background: -webkit-linear-gradient(
    360deg,
    rgba(2, 100, 255, 1),
    rgba(23, 232, 132, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Advanced_battery_main_headings h2 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  padding-bottom: 21px;
}

.Advanced_battery_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  width: 60%;
  margin: auto;
}

.battery_big_img_div {
  margin-top: 56px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* =====gif_number_section======= */

.gif_number_section {
  background: #02020a;
  text-align: center;
  padding: 74px 80px;
}
.gif_number_section .img_box {
  width: 100%;
  max-width: 90%;
  height: auto;
  margin: auto;
}
.gif_number_section .img_box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.gif_number_section .desktop_img {
  border-radius: 25px;
  width: 100%;
}
.gif_number_section .mob_img {
  display: none;
}

/* =====Numbers_Section_main======= */

.Numbers_Section_main {
  background: #02020a;
  padding: 120px 0 201px 0;
  position: relative;
  overflow: hidden;
}
.Numbers_Section_main .tabs_div {
  margin-bottom: 0;
  position: relative;
}
.number_bg_1 {
  position: absolute;
  top: -110px;
  left: 0;
  width: 552px;
}

.sidering {
  position: absolute;
  right: 0;
  top: -50px;
  transform: rotate(-20deg);
}

.Numbers_Section_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.Numbers_Section_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  width: 55%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 73px;
}
.Numbers_Section_main_headings p .bold_color {
  color: #fff;
}
.Numbers_Section_main .tabs_div .nav-tabs {
  justify-content: center;
  margin: 0 !important;
  margin-bottom: 84px !important;
}

.Numbers_Section_main .nav-tabs .nav-item {
  margin-left: 43px;
  margin-right: 43px;
}

.Numbers_Section_main .number_img_1 {
  width: 100%;
}

.number_side_img_col {
  position: relative;
  padding-left: 100px;
}
.full_img {
  margin-left: 100px;
}
.numbers_img_tag {
  position: absolute;
  top: 23px;
  left: 75px;
  width: 210px;
}

.number_bg_tab_1 {
  position: absolute;
  right: 14%;
  bottom: -270px;
  width: 650px;
}

.number_bg_tab_2 {
  position: absolute;
  right: 10%;
  bottom: -270px;
  width: 650px;
}

.number_side_content_col {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.number_side_content_col_single {
  margin-bottom: 60px;
  text-align: end;
  transition: 0.3s all ease-in-out;
}
.number_side_content_col_single:last-child {
  margin-bottom: 0;
}
.number_side_content_col_single h2 {
  position: relative;
  transition: 0.3s all ease-in-out;
}
.number_side_content_col_single .num {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 26px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 5px;
  position: absolute;
  bottom: -46px;

  left: -20px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.number_side_content_col_single .unit {
  margin-left: 10px;
}
.number_side_content_col_single h2 span {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 5px;
  transition: 0.3s all ease-in-out;
}

.number_side_content_col_single h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #bbbbbc;
  margin-bottom: 0;
  background: #02020a;
  position: relative;
}
.number__section:hover .number_side_content_col_single .num {
  bottom: -0px;
  left: -20px;
  opacity: 1;
}
.number__section:hover .number_side_content_col_single .endValue1 {
  right: 80px;
}
.number__section:hover .number_side_content_col_single .endValue2 {
  right: 80px;
}

.number__section:hover .number_side_content_col_single .endValue3 {
  right: 55px;
}
.number__section:hover .number_side_content_col_single .endValue4 {
  right: 80px;
}

/* =====invest_tech_main_section======= */

.invest_tech_main_section {
  background: #02020a;
  position: relative;
}

.invest_tech_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.invest_tech_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-top: 8px;
  padding-bottom: 80px;
  margin-bottom: 0;
}

.invest_tech_boxes {
  display: flex;
  margin-bottom: 88px;
}

.invest_tech_boxes_img {
  margin-right: 34px;
}

.invest_tech_boxes_img img {
  width: 80px;
  height: 80px;
  min-width: 80px;
}

.invest_tech_boxes h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.invest_tech_boxes p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  margin: 8px 0 0 0;
  padding-bottom: 0;
}

/* =====Battery_Form_main_section======= */

.Battery_Form_main_section {
  background: #02020a;
  position: relative;
  padding-top: 4px;
  padding-bottom: 202px;
}

.number_img_11 {
  display: none;
}
.numbers_img_mob,
.number_img_2_mob {
  display: none;
}

.number1_mobile {
  display: none;
}

.mobile_count_num {
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 24px;
}
@media screen and (max-width: 1600px) {
  .number_bg_4 {
    right: 18%;
  }
}
@media screen and (max-width: 1440px) {
  .number_bg_4 {
    right: 16%;
  }
}
@media screen and (max-width: 1200px) {
  .battery_bg_arrow {
    bottom: 400px;
    top: auto;
    width: 150px;
  }

  .Numbers_Section_main_headings p {
    width: 80%;
  }

  .number_bg_4 {
    right: 161px;
    bottom: 222px;
  }
  .gif_number_section .img_box {
    height: auto;
    width: auto;
  }
}

@media screen and (max-width: 992px) {
  .Advanced_battery_main_headings h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    padding-top: 31px;
    padding-bottom: 80px;
  }

  .Advanced_battery_main_headings h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 16px;
  }

  .Advanced_battery_main_headings p {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .battery_bg_arrow {
    width: 80px;
  }

  .Advanced_battery_main_section {
    padding-bottom: 90px;
  }

  .gif_number_section {
    padding: 25px 20px;
  }

  .Numbers_Section_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }

  .Numbers_Section_main_headings p {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 43px;
  }

  .number_bg_1 {
    display: none;
  }

  .Numbers_Section_main {
    padding-top: 87px;
  }

  .Numbers_Section_main .tabs_div .nav-tabs {
    margin-bottom: 36px !important;
  }

  .number_side_content_col_single .num {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  .number_side_content_col_single h2 span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .number_side_content_col_single h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .invest_tech_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    font-family: 'Lufga500';
  }

  .invest_tech_main_headings p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .invest_tech_boxes h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    font-family: Lufga600;
    letter-spacing: -0.12px;
  }

  .invest_tech_boxes p {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
  }

  .invest_tech_boxes_img img {
    min-width: 35px;
    width: 35px;
    height: auto;
  }

  .invest_tech_boxes {
    margin-bottom: 24px;
  }

  .Battery_Form_main_section {
    padding-top: 22px;
    padding-bottom: 152px;
  }
  .numbers_img_mob {
    display: block;
    width: 100%;
    margin-bottom: 32px;
  }

  .Numbers_Section_main {
    padding-bottom: 80px;
  }

  .number_side_content_col_single:last-child {
    margin-bottom: 0;
  }
  .full_img {
    margin-left: 0;
  }
  .number_bg_tab_1 {
    position: absolute;
    right: 14%;
    bottom: -8px;
    width: 495px;
    z-index: -1;
  }
  .number_side_content_col_single {
    margin-bottom: 36px;
  }
  .number_side_content_col_single .num {
    opacity: 1;
    bottom: 0;
    left: 0;
  }
  .last_unit {
    margin-left: 45px !important;
  }
  .number1_desktop {
    display: none;
  }
  .number1_mobile {
    display: block;
  }
  .number_side_content_col_single h3 {
    background-color: transparent;
  }
}

@media screen and (max-width: 767px) {
  .gif_number_section .mob_img {
    display: block;
  }
  .gif_number_section .desktop_img {
    display: none;
  }
  .battery_bg_arrow {
    width: 70px;
    bottom: 256px;
  }

  .number_bg_4 {
    width: 200px;
    height: 200px;
    right: 50px;
    top: 40%;
  }

  .number_side_content_col_single {
    margin-right: 77px;
  }
  .number_side_content_col_single {
    margin-bottom: 0px;
    margin-top: 36px;
  }
  .number_bg_tab_1 {
    position: absolute;
    right: -21%;
    bottom: 0;
    top: 90px;
    width: 307px;
  }
  .number_bg_tab_2 {
    position: absolute;
    right: -21%;
    bottom: 0;
    top: 90px;
    width: 307px;
  }
  .number_side_content_col_single h2 {
    margin-bottom: 2px;
  }
  .number__section:hover .number_side_content_col_single .endValue1 {
    right: 52px;
  }
  .number__section:hover .number_side_content_col_single .num {
    bottom: -3px;
  }
  .number__section:hover .number_side_content_col_single .endValue2 {
    right: 24px;
  }
  .number__section:hover .number_side_content_col_single .endValue3 {
    right: 38px;
  }
  .number__section:hover .number_side_content_col_single .endValue4 {
    right: 18px;
  }
  .number_side_content_col_single .num {
    font-size: 32px;
  }
  .invest_tech_boxes_img {
    margin-right: 30px;
  }
  .Numbers_Section_main .nav-tabs .nav-item {
    margin-left: 0;
    margin-right: 0;
  }
  .numbers_img_tag {
    width: 133px;
    left: 2px;
  }
  .number_img_2 {
    padding-left: 14px;
  }

  .Battery_Hero_main_section {
    margin-top: 75px;
  }
  .Battery_Hero_main_section video {
    transform: scale(1.05);
  }
}

@media screen and (max-width: 500px) {
  .battery_bg_arrow {
    width: 50px;
    bottom: 300px;
    right: 2px;
  }
  .sidering {
    display: none;
  }
  .number_bg_4 {
    width: 200px;
    right: -66px;
    top: 49%;
  }
  .number_img_1,
  .number_img_2 {
    display: none;
  }
  .number_img_11,
  .number_img_2_mob {
    display: block;
  }
  .number_img_2_mob {
    display: block;
    padding-left: 14px;
  }
  .number_img_2_mob {
    width: 347px;
    height: 289px !important;
  }
}
