.race_vault_main_section .about_hero_main_section .main_para {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.vault_big_img_section {
  background: #02040c;
}

/* ============================================ */
/* ============RACE__Vault__main_section========== */
/* ============================================ */

.RACE__Vault__main_section {
  background: #02040c;
  padding: 120px 0;
  position: relative;
}

.half_ring {
  position: absolute;
  top: 30px;
  left: 0;
  width: 54px;
  height: 54px;
}

.RACE__Vault__main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.RACE__Vault__main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  padding: 24px 0 56px 0;
  width: 65%;
  margin: auto;
}

.RACEVaultSlider_single_box {
  background: #1b1b22;
  border-radius: 20px;
  margin-right: 24px;
}

.RACEVaultSlider_single_box_content {
  padding: 18px 16px 14px 16px;
}

.RACEVaultSlider_single_box_content h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #f1f1fa;
  margin-bottom: 10px;
}

.RACEVaultSlider_single_box_content p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 0;
}

.RACEVaultSlider_single_box img {
  border-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  width: 378px;
  height: 278px;
  object-fit: cover;
}

.RACE__Vault__main_section .react-multiple-carousel__arrow,
.RACE__Vault__main_section .react-multi-carousel-dot-list {
  display: none;
}

.slider_bottom_content_section {
  margin-top: 38px;
}

.slider_bottom_content_single_box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.slider_bottom_content_single_box img {
  margin-right: 16px;
  width: 38px;
}

.slider_bottom_content_single_box p {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 0;
}

.slider_bottom_content_single_box h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #f1f1fa;
}

/* ============================================ */
/* ============plant bg _section========== */
/* ============================================ */

.plant_bg_main_section {
  position: relative;
}

.plant_bg_main_section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.plant_bg_main_section h1 span {
  color: #0264ff;
}

.plant_bg_main_section h1 .span2 {
  color: #17e884;
}

/* ============================================ */
/* ============Invest form section========== */
/* ============================================ */

.invest_form_main_section {
  background: #02040c;
  padding: 120px 0;
  position: relative;
}

.invest_form_main_headings h1 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.invest_form_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-top: 8px;
  margin-bottom: 24px;
}

.invest_form_main_border {
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);

  padding: 2px;
  border-radius: 32px;
}

.invest_form_main_box {
  padding: 80px 61px;
  border-radius: 32px;
  background: #02040c;
}

.invest_form_main_box h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-bottom: 48px;
}

.invest_form_main_box h4 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  /* margin-top: 30px; */
  margin-bottom: 24px;
}

.flex_end_content {
  display: flex;
  align-items: flex-end;
}

.flex_end_content .form_submit_btn {
  /* margin-bottom: 30px; */
}

/* ============================================ */
/* ============Investors Slider section========== */
/* ============================================ */

.InvestorsSlider_main_section {
  background: #02040c;
  padding-bottom: 120px;
  position: relative;
}
.InvestorsSlider_main_section_inner {
  min-height: 350px;
}
.InvestorsSlider_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 56px;
}

.InvestorsSlider_single h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.InvestorsSlider_single p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #bbbbbc;
  margin-top: 16px;
  margin-bottom: 24px;
}

.Investors_small_img,
.Investors_small_img a {
  display: flex;
  align-items: center;
}
.Investors_small_img a {
  text-decoration: none;
}
.Investors_small_img img {
  max-width: 64px;
}

.Investors_small_img h4 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-left: 12px;
}

.InvestorsSlider_quote_col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.InvestorsSlider_main_section .carousel-indicators {
  bottom: -20% !important;
}

.InvestorsSlider_main_section .carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  margin-left: 8px !important;
}

.InvestorsSlider_main_section .carousel-indicators .active {
  width: 26.67px !important;
}

/* ============================================ */
/* ============Reduce Pollution section========== */
/* ============================================ */

.ReducePollution_main_section {
  background: #02040c;
  padding-bottom: 186px;
  position: relative;
}

.Reduce_Pollution_main_inner {
  position: relative;
  height: 500px;
  width: 100%;
}

.Reduce_Pollution_main_inner video,
.Reduce_Pollution_main_inner img {
  border-radius: 16px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Reduce_Pollution_video_bg {
  background: linear-gradient(180deg, rgba(27, 27, 34, 0) 0%, #1b1b22 69.27%);
  border-radius: 4px 4px 16px 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 222px;
}

.Reduce_Pollution_content_main {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 65px 25px 65px;
}

.Reduce_Pollution_content_main h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.Reduce_Pollution_content_main h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-top: 14px;
  margin-bottom: 4px;
}

.Reduce_Pollution_content_main p {
  margin-bottom: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #bbbbbc;
}
.border_right {
  border-right: 1px solid #bbbbbc;
}
.border_right_40 {
  padding-left: 40px;
}

.react-multi-carousel-dot--active button {
  width: 50px !important;
  height: 6px !important;
  border: none !important;
  background: #fcfcfc !important;
  border-radius: 30px !important;
}

.RACE__Vault__main_section .react-multi-carousel-dot button {
  width: 8px;
  height: 8px !important;
  border: none !important;
  background: #fcfcfc !important;
  opacity: 0.5 !important;
  margin: 0;
  margin-left: 8px;
}
.RACE__Vault__main_section .react-multi-carousel-dot--active button {
  width: 26px !important;
  opacity: 1 !important;
}
@media screen and (max-width: 1024px) {
  .RACE__Vault__main_section .react-multi-carousel-list {
    position: static !important;
  }
  .RACE__Vault__main_section .react-multi-carousel-dot-list {
    display: flex;
    bottom: 220px;
  }
  .slider_bottom_content_section {
    margin-top: 80px;
  }
}
@media screen and (max-width: 992px) {
  .about_hero_main_section .main_heading {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
  }
  .border_right_40 {
    padding-left: 10px;
  }
  .border_right {
    padding-left: 10px;
    padding-right: 10px;
  }

  .race_vault_main_section .about_hero_main_section .main_para {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding-top: 24px;
  }

  .RACE__Vault__main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }

  .RACE__Vault__main_headings p {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 16px 0 24px 0;
  }

  .RACEVaultSlider_single_box_content h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .RACEVaultSlider_single_box_content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .slider_bottom_content_single_box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .slider_bottom_content_single_box h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .RACE__Vault__main_section {
    padding: 80px 0;
    padding-bottom: 52px;
  }

  .plant_bg_main_section h1 {
    font-weight: 500;
    font-family: 'Lufga500';
    font-size: 36px;
    line-height: 42px;
  }

  .invest_form_main_section {
    padding: 80px 0;
  }

  .invest_form_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }

  .invest_form_main_headings p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .invest_form_main_box h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .invest_form_main_box {
    padding: 40px 20px;
  }

  .InvestorsSlider_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 24px;
    font-family: 'Lufga500';
  }

  .InvestorsSlider_single h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .InvestorsSlider_single p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .Reduce_Pollution_content_main h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }

  .Reduce_Pollution_content_main {
    padding: 16px;
  }

  .Investors_small_img img {
    max-width: 40px;
  }

  .Reduce_Pollution_content_main h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .Reduce_Pollution_content_main p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .Investors_small_img h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }

  .InvestorsSlider_main_section {
    padding-bottom: 100px;
  }

  .ReducePollution_main_section {
    padding-bottom: 151px;
  }

  .invest_form_main_box h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  /**/
}

@media screen and (max-width: 768px) {
  .half_ring {
    width: 30px;
  }
  .border_right {
    border-right: 0;
    border-bottom: 1px solid #bbbbbc;
    /* margin-bottom: 18px;
    margin-top: 18px; */
  }
  .border_right,
  .border_right_40 {
    padding: 0;
  }
  .Reduce_Pollution_content_main p {
    margin-bottom: 8px;
  }
  .slider_bottom_content_single_box {
    margin-bottom: 28px;
    align-items: center;
  }
  .slider_bottom_content_single_box .money_img {
    width: 37.643px !important;
    height: 54px !important;
  }
  .slider_bottom_content_single_box .battery_img {
    width: 29.613px !important;
    height: 54px !important;
  }
  .plant_bg_main_section img {
    width: 100%;
    height: 280px;
  }

  .Reduce_Pollution_main_inner img {
    height: 500px;
  }

  .RACE__Vault__main_section .react-multi-carousel-dot-list {
    bottom: 260px;
  }
}

.qote_small_img_flex img {
  display: none;
}

.qote_small_img_flex {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 766px) {
  .InvestorsSlider_quote_col img {
    display: none;
  }

  .qote_small_img_flex img {
    display: block;
    max-width: 50px;
    margin-left: 20px;
  }
  .RACEVaultSlider_single_box {
    margin-right: 0;
  }
}
