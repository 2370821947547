.new_form_section_main {
  background: #02020a;
}
.new_form_section_main_inner {
  border-radius: 32px;
  padding: 80px 111px;
  border: 2px solid;
  background: #02020a;
}
.new_form_section_main_border {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  padding: 2px;
  border-radius: 32px;
}

.new_input_field_box .new_input_field {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 37px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 8px;

  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.new_input_field_box .new_input_field::placeholder {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.did-floating-label-content {
  position: relative;
}

.did-floating-label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: 11px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.did-floating-input {
  display: block;
  box-sizing: border-box;

  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 37px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 8px;

  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}
.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
}
.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: -12px;
  font-size: 11px;
  left: 0;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -12px;
  font-size: 11px;
  left: 0;
}

.mb_48 {
  margin-bottom: 48px;
}
.mt_56 {
  margin-top: 56px;
}

.did-floating-label-content .custom_drop_down:visited {
  border: none !important;
  outline: none !important;
}

.new_form_section_main h3 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 26px;
}

.new_form_radio_buttons {
  display: flex;
  align-items: center;
}
.new_form_radio_buttons input {
  cursor: pointer;
}
.new_form_radio_buttons label {
  margin: 0 27px 0 8px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding-left: 0;
}

#accent2,
#AreaPartner2,
#Franchise2,
#RACEVault2,
#Fleet2,
#OEM2,
#Other2 {
  accent-color: #17e884 !important;
}

input[type='radio']:checked {
  background-color: #17e884 !important;
  border: 2px solid #17e884 !important;
  appearance: auto !important;
}

.new_form_tabs_content_main {
  margin-top: 32px;
}
.new_form_section_main p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  width: 60%;
  margin-bottom: 26px;
  margin-bottom: 34px;
}

.select-container {
  position: relative;
}

.select-label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;

  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.select-label.top {
  top: -6px;
  font-size: 11px;
  left: 0;
}

.new_select__box {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 37px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 8px;

  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}
.new_select__box option {
  background: #02020a;
  border-bottom: 1px solid #191920;

  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 54px !important;
  color: #bbbbbc;
}
.new_select__box {
  -webkit-appearance: none;
  -moz-appearance: window;
  background-image: url('../imgs/drop-down.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px 5px;
  cursor: pointer;
}
