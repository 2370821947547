.Network_Hero_main_section {
  position: relative;
}

.Network_Hero_main_section_inner {
  width: 100%;
  object-fit: fill;
  object-fit: contain;
}

.Network_Hero_content_main {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.Network_Hero_content_main h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
}

.top_slider_main_div {
  position: absolute;
  bottom: -93px;
  left: 50%;
  width: 20%;
  margin: auto;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
}
.top_slider_main_div img {
  width: 510px;
  height: 510px;
}
.top_slider_main_div .carousel-indicators {
  display: none !important;
}
/* =========map_main_section======== */

.map_main_section {
  background-color: #02020a;
  padding-top: 202px;
  padding-bottom: 200px;
  position: relative;
  margin-top: -8px;
}
.map_main_section h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 45px;
  position: relative;
  z-index: 9;
}
.map_bg1 {
  position: absolute;
  top: 0;
  right: 50px;
}
.map_bg2 {
  position: absolute;
  bottom: 0;
  left: 0px;
}

/* =========features_main_section======== */

.features_main_section {
  background-color: #02020a;
  position: relative;
  padding-bottom: 10px;
}
.feature_1_img {
  width: 435px;
}
.feature_2_img {
  width: 538px;
}
.feature_3_img {
  width: 334px;
}
.feature_4_img {
  width: 414px;
}

.feature_5_img {
  width: 424px;
}

.feature_6_img {
  width: 410px;
}
.feature_7_img {
  width: 446px;
}
.feature_8_img {
  width: 455px;
}
.feature_9_img {
  width: 428px;
}
.feature_10_img {
  width: 442px;
}
.feature_11_img {
  width: 410px;
}
.features_arrow {
  position: absolute;
  right: 0;
  bottom: 0;
}
.features_ring {
  position: absolute;
  right: 0;
  top: 500px;
  width: 148px;
}
.features_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 90px;
}
.features_img_col {
  position: relative;
}
.features_img_four img {
  margin-left: 50px;
}
.features_img_six img {
  margin-left: 100px;
  margin-top: -50px;
}
.features_img_seven img {
  position: relative;
  z-index: 1;
  margin-top: -20px;
}
.features_img_eight img {
  margin-left: 120px;
  z-index: 1;
  position: relative;
}
.features_img_nine img {
  margin-top: -20px;
  z-index: 1;
  position: relative;
}
.features_img_ten img {
  margin-left: 120px;
  margin-top: -50px;
}
.features_img_eleven img {
  margin-top: -70px;
  margin-left: -50px;
  position: relative;
  z-index: 1;
}

.features_img_col h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 140px;
  letter-spacing: 0.005em;
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.3;
}
.left_side {
  position: absolute;
  top: -60px;
  left: -50px;
}
.right_side {
  position: absolute;
  top: -60px;
  right: -50px;
}
.left_side_eleven {
  left: 0px;
}
.features_content_col h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  background: -webkit-linear-gradient(180deg, #17e884 50%, #0264ff 7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.features_content_col h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin: 8px 0 16px 0;
}
.features_content_col p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 0;
}
.features_content_col_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.features_content_row {
  margin-bottom: 110px;
}
.features_content_col_left {
  padding-right: 80px;
}
.features_content_col_right {
  padding-left: 80px;
}

/* =========host_main_section======== */

.host_main_section .invest_tech_boxes p {
  padding-right: 70px;
}

.host_main_section .invest_tech_boxes_img img {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
  margin-top: 10px;
}
.host_main_section .width_62 img {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  margin-top: 14px;
}
.network_mob_banner {
  display: none;
}
.Network_Hero_content_main_mobile {
  display: none;
}
@media screen and (max-width: 1200px) {
  .features_content_col_right {
    padding-left: 50px;
  }
  .features_content_col_left {
    padding-right: 50px;
  }
  .Network_Hero_main_section_inner {
    height: 70vh;
  }
}
@media screen and (max-width: 1024px) {
  .features_content_col_right {
    padding-left: 0px;
  }
  .features_content_col_left {
    padding-right: 0px;
  }
}

@media screen and (max-width: 991px) {
  .Network_Hero_main_section {
    margin-top: 70px;
  }
  .host_main_section .width_62 img {
    margin-top: 10px;
  }
  .Network_Hero_content_main_mobile {
    margin-top: -6px;
  }
  .top_slider_main_div img {
    width: 160px;
    height: 160px;
  }

  .Network_Hero_content_main h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    margin-bottom: 0;
    padding-top: 24px;
    display: none;
  }
  .Network_Hero_content_main_mobile {
    background: #02020a;
    display: block;
  }
  .Network_Hero_content_main_mobile h1 {
    color: #fcfcfc;
    text-align: center;
    font-family: Lufga600;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.384px;
    margin-bottom: 0;
    padding: 15px;
    padding-top: 112px;
  }

  .map_main_section h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 16px;
  }
  .features_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  .map_bg1 {
    width: 45px;
    top: -210px;
    right: 12px;
  }
  .map_bg2 {
    width: 50px;
    top: 100px;
  }
  .map_main_section {
    padding-bottom: 80px;
    padding-top: 120px;
  }
  .features_content_col h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Lufga600';
    letter-spacing: -0.12px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .network_img {
    width: 102px !important;
  }
  .grid_img {
    width: 41px !important;
  }
  .swap_img {
    width: 140px !important;
  }
  .features_content_col h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  .features_content_col p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .features_ring {
    display: none;
  }

  .features_arrow {
    width: 116.553px;
    right: -22px;
  }
  .right_side,
  .left_side {
    left: 50%;
    right: auto;
    transform: translate(-50%);
  }
  .features_img_col h1 {
    font-weight: 600;
    font-size: 96px;
    line-height: 72px;
  }
  .features_img_four img {
    margin-left: 0;
  }
  .features_img_six img,
  .features_img_eight img,
  .features_img_ten img,
  .features_img_eleven img {
    margin: 0;
  }
  .features_content_row {
    margin-bottom: 154px;
  }
  .host_main_section .invest_tech_boxes_img img {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    margin-top: 10px;
  }
  .host_main_section .invest_tech_boxes_img .home_img {
    min-width: 26px;
    min-height: 26px;
    width: 26px;
    height: 26px;
  }
  .Network_Hero_main_section_inner {
    height: auto;
  }
  .network_mob_banner {
    display: block;
  }
  .Network_Hero_content_main {
    position: relative;
    transform: translate(-50%, 0);
    background: #02020a;
  }
  .Network_Hero_main_section_inner img {
    margin-top: 70px;
  }
  .host_main_section .invest_tech_boxes p {
    padding-right: 0;
  }
  .invest_tech_main_headings p {
    padding-bottom: 46px;
  }
  .invest_tech_boxes p {
    padding-bottom: 0;
  }
  .feature_1_img {
    width: 350px;
    height: 287px;
  }
  .feature_2_img {
    width: 350px;
    height: 243.309px;
  }
  .feature_3_img {
    width: 350px;
    height: 422.09px;
  }
  .feature_4_img {
    width: 350px;
    height: 346.595px;
  }
  .feature_11_img {
    width: 350px;
    height: 268px;
  }
  .features_img_eleven img {
    margin-top: -75px;
  }
  .feature_10_img {
    width: 350px;
    height: 323.967px;
  }
  .features_img_ten img {
    margin-top: -30px;
  }
  .feature_9_img {
    width: 350px;
    height: 333.114px;
  }
  .features_img_nine img {
    margin-top: -35px;
  }
  .feature_7_img,
  .feature_6_img,
  .feature_5_img {
    width: 350px;
  }
  .feature_5_img {
    margin-top: -50px;
  }
  .feature_6_img {
    margin-top: -15px !important;
  }
}

.features_content_row:last-child {
  margin-bottom: 100px;
}

@media screen and (max-width: 500px) {
  .map_bg1 {
    top: -320px;
  }
}
