.article_main_width {
  width: 70%;
  margin: auto;
}

.article_hero_main_section {
  background: rgba(255, 255, 255, 0.08);
  padding-top: 135px;
  position: relative;
}

.article_hero_main_headings {
  position: relative;
}

.back_img {
  position: fixed;

  left: 80px;
  top: 153px;
}

.next_img {
  position: fixed;
  right: 80px;
  top: 153px;
}
.article_back_next_btn {
  width: 48px;
  height: 48px;
  background: #4f4f4f;
  opacity: 0.6;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
  z-index: 999;
}
.article_back_next_btn img {
  width: 22px;
}
.article_back_next_btn:hover {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  opacity: 0.6;
}
.article_hero_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin: auto;
}

.article_by_date_div {
  text-align: center;
  margin: 16px 0;
}

.article_by_date_div span {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  opacity: 0.6;
}

.article_by_date_div .color_span {
  color: #0264ff;
  opacity: 0.6;
}

.article_tags {
  text-align: center;
}

.article_tags div {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4f4f4f;
  opacity: 0.6;
  display: inline;
  margin-left: 5px;
}

.article_hero_main_headings p {
  margin-bottom: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #252527;
  opacity: 0.7;
  margin-top: 35px;
  margin-bottom: 32px;
}

/* ===========ArticleSlider_main_slider=========== */

.ArticleSlider_main_slider {
  margin-bottom: 54px;
}

.ArticleSlider_main_single_border {
  background-color: #0264ff;
  border-radius: 32px;
  padding: 2px;
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  margin-top: 30px;
}

.ArticleSlider_main_single {
  padding: 36px 32px;
  background: rgba(255, 255, 255);
  border-radius: 32px;
}

.ArticleSlider_main_single h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
}

.ArticleSlider_main_single p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252527;
  margin-top: 16px;
  margin-bottom: 36px;
}

.article_learn_more_div {
  display: flex;
  justify-content: flex-end;
}

.article_learn_more_div a {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: #0264ff;
  text-decoration: none;
}

.ArticleSlider_main_slider .carousel-indicators {
  bottom: -18%;
}

.ArticleSlider_main_slider .carousel-indicators [data-bs-target] {
  background: #252527 !important;
  opacity: 0.32;
  height: 8px !important;
  width: 8px !important;
  margin-left: 8px !important;
}

.ArticleSlider_main_slider .carousel-indicators .active {
  opacity: 1 !important;
  width: 26px !important;
}

.ArticleSlider_main_single_border img {
  max-width: 199.02px;
  max-height: 160px;
  border-radius: 8px;
}

/* ===========ArticleSlider_main_slider=========== */

.article__main_section_content h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin-bottom: 16px;
}

.article__main_section_content p {
  margin-bottom: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #252527;
  opacity: 0.7;
}

.article__main_section_content .pb_16px {
  padding-bottom: 16px;
}

.article__main_section_content .ptb_32 {
  padding: 32px 0;
}

.article__main_section_content .pb_32 {
  padding-bottom: 32px;
}

.article__main_section_content .para_right_margin p {
  padding-right: 80px;
}

.article__main_section_content h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
}

.article_share_btn_div {
  margin-top: 16px;
  width: 48px;
  height: 48px;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  opacity: 0.6;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article_share_btn_div img {
  max-width: 18px;
}

/* ===========Recommended_Reads_section=========== */

.Recommended_Reads_section_main {
  position: relative;
  margin-top: 116px;
  padding-bottom: 166px;
}

.news_bg_fade1 {
  position: absolute;
  right: 0;
  top: 0;
  backdrop-filter: blur(60px);
}

.news_bg_fade2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 259.12px;
  height: 259.12px;
  backdrop-filter: blur(60px);
}

.news_bg_fade3 {
  position: absolute;
  right: 0;
  top: 0;
  width: 466.47px;
  height: 318.87px;
  backdrop-filter: blur(60px);
}

.news_bg_fade4 {
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 466.47px;
  height: 318.87px;
  backdrop-filter: blur(60px);
}

.Recommended_Reads_main_border {
  background: linear-gradient(
    143.11deg,
    rgba(255, 255, 255, 0.1) 11.08%,
    rgba(218, 247, 233, 0.094) 36.26%
  );
  backdrop-filter: blur(60px);
  border-radius: 24px;
  padding: 2px;
}

.Recommended_Reads_main {
  border-radius: 24px;
  padding: 54px 60px 80px 60px;
  border: 1px solid #d9d9d9;
}

.Recommended_Reads_main .carousel-indicators .active {
  opacity: 1 !important;
  width: 26px !important;
}
.Recommended_Reads_main h1 {
  margin-bottom: 0;
  text-align: center;
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin-bottom: 60px;
}

.Recommended_Reads_section_main .new_box_main {
  background-color: #fff;
}

.Recommended_Reads_section_main .new_box_main img {
  border-radius: 18px;
}
.Recommended_Reads_section_main .new_box_main .learn_arrow {
  border-radius: 0;
}
.share-buttons {
  display: flex;
  align-items: center;
}
.artcile_share_icons {
  font-size: 22px;
  border-radius: 10px;
  text-decoration: none;
  margin-right: 20px;
}

.Recommended_Reads_main .react-multiple-carousel__arrow {
  display: none;
}
.Recommended_Reads_main .react-multi-carousel-item {
  min-height: 530px;
}
.Recommended_Reads_main .react-multi-carousel-dot-list {
  margin-left: -50px;
}
.Recommended_Reads_main .react-multi-carousel-dot button {
  background: #000 !important;
  opacity: 0.32;
  height: 8px !important;
  width: 8px !important;
  border: none !important;
}
.Recommended_Reads_main .react-multi-carousel-dot--active button {
  width: 26px !important;
  height: 8px !important;
  background-color: #252527 !important;
  opacity: 1;
}
.react-multi-carousel-dot--active button {
  background-color: red !important;
}
.blog_details_short_desc {
  font-size: 18px !important;
}
.border_radius input,
.border_radius img {
  border-radius: 30px !important;
  max-width: 100% !important;
  height: auto !important;
}
.border_radius p,
.border_radius span,
.border_radius span span {
  color: #252527 !important;
  font-family: Manrope, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 1200px) {
  .article_main_width {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .article_main_width {
    width: 100%;
  }

  .article_hero_main_headings h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
  }

  .article_by_date_div span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .article_tags span {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  .article_tags div {
    display: inline-block;
  }

  .article_hero_main_headings p {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .ArticleSlider_main_single h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }

  .ArticleSlider_main_single_border img {
    max-width: 100%;
    width: 100%;
    max-height: none;
  }

  .ArticleSlider_main_single p {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 18px;
  }

  .ArticleSlider_main_single {
    padding: 20px;
  }

  .ArticleSlider_main_slider .carousel-indicators {
    bottom: -25%;
  }

  .article__main_section_content h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .article__main_section_content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .article__main_section_content .para_right_margin p {
    padding-right: 0;
  }

  .para_right_margin img {
    margin-top: 16px;
  }

  .article__main_section_content h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  .Recommended_Reads_section_main {
    margin-top: 48px;
    padding-bottom: 119px;
  }

  .Recommended_Reads_main {
    padding: 20px;
  }

  .Recommended_Reads_main h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .Recommended_Reads_main .new_box_main h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .ArticleSlider_main_slider .carousel-indicators {
    bottom: -10%;
  }
  .back_img {
    top: 93px;

    left: 20px;
    width: 30px;
    height: 30px;
  }
  .next_img {
    right: 20px;
    top: 93px;
    width: 30px;
    height: 30px;
  }
  .article_back_next_btn img {
    width: 16px;
  }
  .Recommended_Reads_main .react-multi-carousel-item {
    min-height: 500px;
  }
  .Recommended_Reads_main .react-multi-carousel-dot-list {
    margin-left: 0px;
  }
}
