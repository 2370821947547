/* ===============partnership modal================ */

.Partnership_Models_main_section .tabs_div .nav-item button:hover {
  border: none;
  border: 1px solid #02020a;
}
.Partnership_Models_main_section .tabs_div .nav-tabs .nav-link.active:hover {
  border: 1px solid #17e884;
}
.partner_hero_main .main_para {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;

  opacity: 0.7;
  width: 50%;
}
.Partnership_Models_main_section {
  position: relative;
}

.mb__36 {
  margin-bottom: 36px;
}
.Partnership_Models_main_section .join_our_main_section {
  padding-top: 0;
  padding-bottom: 120px;
}

.Partnership_Models_main_section .tabs_div {
  margin-bottom: 0;
}

.Partnership_Models_main_section .side_arrow {
  position: absolute;
  top: -170px;
  left: 0;
  z-index: 99999;
}

.Partnership_Models_main_section .join_our_main_section .tabs_div_inner {
  margin: 32px auto 36px auto !important;
  width: 60%;
}

.partner_model_tabs_content h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.partner_model_tabs_content p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  padding-bottom: 36px;
  padding-top: 16px;
  width: 60%;
  margin: auto;
}

.auto_img_bg_div {
  position: relative;
}

.auto_img_content_main {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 35px 31px 36px 35px;
}

.auto_img_content_box p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin-top: 16px;
  margin-bottom: 0;
}

/* ===============Lets talk about investment================ */
.investment_main_section {
  position: relative;
}

.investment_main_section .partnership_main_section {
  padding: 0;
  padding-bottom: 120px;
}

.Investment_money {
  position: absolute;
  top: 26px;
  left: -33px;
  z-index: 999;
  width: 294px;
}
.Investment_money_mob {
  display: none;
  position: absolute;

  top: 26px;
  left: -11px;
  z-index: 999;
  width: 157px;
  height: 46px;
}

.investment_main_section .partnership_main_headings p {
  width: 60%;
}

.partnership_bottom_left {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
  width: 313px;
  height: 124px;
}

/* ===============Partnership_slider_main_section================ */
.Partnership_slider_main_section {
  background: #02020a;
}

.Partnership_slider_main_headings h1 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.Partnership_slider_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  padding-top: 24px;
  padding-bottom: 48px;
  width: 70%;
}

.Partnership_slider_section_main {
  padding-bottom: 70px;
}

.Partnership_slider_content_side {
  display: flex;

  margin-right: 43px;
}

.Partnership_slider_content_side_icon {
  min-width: 60px;
  margin-right: 30px;
}

.Partnership_slider_content_side_icon img {
  max-width: 60px;
  max-height: 60px;
}

.Partnership_slider_content_side h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.Partnership_slider_content_side p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fcfcfc;
  margin-top: 16px;
}

.slider_imgs img {
  border-radius: 16px;
}

.Partnership_slider_main_section .carousel-indicators {
  top: 0%;
  left: auto;
  right: -12% !important;
  display: flex;
  flex-direction: column;
}

/* ===============partners_main_Section================ */
.partners_main_Section {
  background: #02020a;
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}

.partner_bg {
  position: absolute;
  left: 0;
  top: 200px;
}

.partners_main_Section_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 48px;
}

.partner_box {
  margin-bottom: 36px;
}
.Existing_partners_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 36px;
}
.Existing_partners_div h4 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-right: 36px;
}
.Existing_partners_div img {
  margin-right: 11px;
}

/* ===============Become_our_Partner_main_section================ */

.Become_our_Partner_main_box_border {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  padding: 2px;
  border-radius: 32px;
}

.Become_our_Partner_main_section {
  background: #02020a;
  padding-bottom: 245px;
  position: relative;
}

.partner_bg2 {
  position: absolute;
  right: 0;
  top: 200px;
}

.Become_our_Partner_main_box {
  padding: 80px 111px;
  background: #02020a;
  border-radius: 32px;
  position: relative;
  z-index: 99;
}

.Become_our_Partner_main_box h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.Become_our_Partner_main_box p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-top: 16px;
  margin-bottom: 48px;
}

.footprint_form .input_box {
  padding-bottom: 30px;
}

.Partnership_slider_section_main_mobile {
  display: none;
}
.scroller_slider_main {
  max-height: 360px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
.slider_blue_color {
  color: #026df3;
}
.slider_grident_color {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.scroller_slider_main::-webkit-scrollbar {
  width: 0px;
}

.scroller_slider_main::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroller_slider_main_inner_single {
  position: relative;
  margin-bottom: 50px;
}

.scroller_slider_indicators_dots_main {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.scroller_slider_indicators_dots {
  width: 5px;
  height: 20px;
  background-color: #fcfcfc;
  opacity: 0.32;
  margin-top: 8px;
  border-radius: 50px;
}
.scroller_slider_indicators_dots_active {
  background-color: #fcfcfc;
  height: 60px;
  width: 5px;
  margin-top: 8px;
  border-radius: 50px;
}
.readmore_btn a {
  color: #0264ff;
  font-size: 14px;
  font-family: Lufga600;
  font-weight: 700;
  letter-spacing: 1.68px;
  text-transform: uppercase;
  text-decoration: none;
}
.readmore_btn img {
  margin-top: -7px;

  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.readmore_btn:hover img {
  opacity: 1;
}
.Franchise_mob {
  display: none;
}
.partner_race_vault_img {
  width: 286px;
  height: 286px;

  margin-top: -20px;
}

.partner_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partner_tabs li {
  border-radius: 72px;
  color: #bbbbbc;
  font-family: Lufga400;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 32px;
  white-space: nowrap;
  padding: 8px 16px;
  border: 1px solid transparent;
  cursor: pointer;
  list-style: none;
}
.partner_tabs .active {
  border: 1px solid #17e884;
  color: #17e884;
}
.partner_tabs li:hover {
  color: #17e884;
}
@media screen and (max-width: 1024px) {
  .investment_main_section .partnership_main_headings p {
    width: 100%;
  }

  .Become_our_Partner_main_box {
    padding: 40px 50px;
  }
}

@media screen and (max-width: 992px) {
  .partner_hero_main .about_hero_main_section .main_heading {
    font-family: 'Lufga600';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
  }

  .partner_hero_main .about_hero_main_section .main_para {
    width: 100%;
  }

  .Partnership_Models_main_section .join_our_main_section .tabs_div_inner {
    width: 100%;
  }

  .partner_model_tabs_content p {
    width: 100%;
  }

  .auto_img_content_main {
    position: initial;
  }

  .partnership_main_headings h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .investment_main_section .partnership_main_headings p {
    font-size: 18px;
    line-height: 24px;
  }

  .investment_main_section .partnership_single_box_content h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .investment_main_section .partnership_single_box {
    padding-top: 40px;
  }

  .Partnership_slider_main_section .Partnership_slider_main_headings h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .Partnership_slider_main_headings p {
    width: 100%;
  }

  .Partnership_slider_content_side h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .Partnership_slider_content_side {
    margin-right: 20px;
  }

  .Partnership_slider_content_side_icon {
    margin-right: 10px;
    width: 40px;
  }

  .Partnership_slider_content_side_icon img {
    max-width: 40px;
    max-height: none;
  }

  .Partnership_slider_main_section .carousel-indicators {
    right: -22% !important;
  }

  .partners_main_Section_headings h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .Become_our_Partner_main_box h1 {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Lufga500';
    font-weight: 500;
    letter-spacing: -0.36px;
  }

  .Become_our_Partner_main_box p {
    font-size: 14px;
    line-height: 22px;
  }

  .Become_our_Partner_main_box {
    padding: 43px 18px;
  }

  .investment_main_section .partnership_main_inner_box {
    padding: 36px 18px;
  }

  .investment_main_section .high_return_img {
    top: -28px;
    width: 17%;
  }
  .Investment_money {
    display: none;
  }
  .Investment_money_mob {
    display: block;
  }
}

@media screen and (max-width: 769px) {
  .investment_main_section .partnership_main_section {
    display: block;
  }

  .Partnership_slider_section_main {
    display: none;
  }
  .partner_race_vault_img {
    margin-top: 0;
  }
  .investment_main_section .high_return_img {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .partner_tabs {
    width: 100%;
    overflow: scroll;
  }
  .partner_tabs li {
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
  }
  .mb__36 {
    margin-bottom: 36px;
  }
  .Partnership_slider_section_main_mobile {
    display: block;
  }

  .Partnership_slider_section_main_mobile .Partnership_slider_content_side {
    margin-right: 0;
  }

  .Partnership_slider_section_main_mobile
    .Partnership_slider_content_side_icon {
    margin-right: 16px;
    min-width: 30px;
  }

  .Partnership_slider_content_side_para {
    margin-bottom: 36px;
    margin-top: 16px;
    font-family: 'Manrope', sans-serif;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #fcfcfc;
  }
  .Partnership_slider_section_main_mobile_row {
    padding-bottom: 48px;
  }
  .Partnership_slider_section_main_mobile
    .Partnership_slider_content_side_icon
    img {
    width: 30px;
  }

  .Partnership_Models_main_section .side_arrow {
    width: 100px;
    top: -110px;
  }

  .Partnership_slider_section_main_mobile .slider_imgs img {
    width: 100%;
    border-radius: 0;
  }

  .Partnership_Models_main_section .join_our_main_section .tabs_div_inner {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }

  .partner_hero_main .about_hero_main_section .main_para {
    padding-top: 24px;
    padding-bottom: 36px;
  }

  .partner_hero_main .about_hero_main_section .arrow_down_btn {
    padding-bottom: 40px;
  }

  .partner_model_tabs_content h2 {
    margin-top: 36px;
    font-size: 24px;
    line-height: 30px;
  }

  .partner_model_tabs_content p {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 24px;
  }

  .auto_img_content_box {
    padding-bottom: 18px;
  }

  .auto_img_content_main {
    padding: 24px 30px;
  }

  .about_bg_fade2 {
    right: -29%;
    top: 68px;
  }

  .about_bg_fade1 {
    right: 0;
    left: -8%;
    top: 6%;
  }

  .Partnership_Models_main_section .join_our_main_section {
    padding-bottom: 1px;
  }

  .investment_main_section .high_return_img {
    top: -23px;
    width: 25%;
  }

  .partnership_bottom_left {
    position: absolute;
    bottom: 0px;
    width: 200px;
  }

  .investment_main_section .partnership_main_section {
    padding-bottom: 80px;
  }

  .Partnership_slider_main_headings p {
    padding-top: 14px;
    padding-bottom: 38px;
  }

  .partner_bg {
    top: 6px;
    width: 61px;
  }

  .partners_main_Section {
    padding: 80px 0;
    padding-bottom: 50px;
    padding-top: 22px;
  }

  .partner_box {
    margin-bottom: 16px;
  }
  .partner_bg2 {
    top: -27px;
    width: 100px;
  }
  .Franchise_mob {
    display: block;
  }
  .Franchise_desktop {
    display: none;
  }
  .partner_hero_main .about_hero_main_section .main_heading {
    width: 100%;
  }
  .investment_main_section .high_return_img {
    width: 30%;
  }
  .ripee {
    width: 13.789px !important;
    height: 21.36px !important;
    margin-left: 5px;
  }
  .ripee_text {
    margin-left: -10px;
  }
}
