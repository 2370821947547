.newsroom_hero_main_section {
  background: #02020a;
  padding-top: 186px;
  position: relative;
}
.newsroom_hero_main_section a {
  text-decoration: none;
}
.newroom_ring {
  position: absolute;
  right: 60px;
  top: 380px;
  width: 114px;
  height: 114px;
}

.newsroom_hero_main_section_headings h1 {
  padding-bottom: 48px;
  margin-bottom: 0;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
}

.newsroom_hero_main_section .tabs_div .nav-tabs {
  margin: 0 !important;
  margin: auto !important;
  padding-bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}

.newsroom_hero_main_section .tabs_div {
  margin-bottom: 0;
}

.news_content_left_side_content_main h4 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.6;
  padding: 2px 10px;
  background: #1b1b22;
  border-radius: 4px;
  display: inline-block;
  margin-top: 24px;
  margin-bottom: 10px;
}

.news_content_left_side_content_main h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 10px;
}

.news_content_left_side_content_main h2 a {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 10px;
  text-decoration: none;
}
.news_by_and_date_div span {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.6;
}

.news_by_and_date_div .news_color {
  margin-right: 5px;
  color: #17e884;
  text-transform: capitalize;
}

.news_by {
  margin-right: 5px;
}
.news_content_left_side_content_main {
  margin-bottom: 30px;
}

.news_content_left_side_content_main p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  opacity: 0.6;
  margin-top: 16px;
}

.news_content_right_side_main_single {
  margin-bottom: 36px;
}

.news_border_bottom {
  border-bottom: 1px solid #bbbbbc !important;
}

/* =================.more stories====================== */

.more_stories_main_section {
  background: #02020a;
  padding-top: 24px;
}

.more_stories_main_headings h1 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #0264ff;
  margin-bottom: 14px;
}
.more_stories_single_box {
  margin-bottom: 40px;
}
.more_stories_single_box .news_by_and_date_div span {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  opacity: 0.6;
}

.more_stories_single_box .news_by_and_date_div .news_color {
  color: #17e884;
  opacity: 0.6;
  text-transform: capitalize;
}

.more_stories_single_box h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-top: 10px;
}
.more_stories_single_box h3 a {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-top: 10px;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

/* =================FeaturedVideos_main_section====================== */

.FeaturedVideos_main_section {
  background: #02020a;
  padding-top: 120px;
  padding-bottom: 54px;
  position: relative;
}

.FeaturedVideos_bg {
  position: absolute;
  top: 100px;
  left: 0;
}

.FeaturedVideos_main_section video {
  border-radius: 16.8333px;
}

.FeaturedVideos_main_section_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 49px;
}

.MoreVideos_main_section .more_stories_main_headings h1 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 24px;
}

.more_stories_single_box video {
  border-radius: 30px;
}

/* =================Press Kit section====================== */

.PressKit_main_section {
  background: #02020a;
  padding-top: 120px;
  padding-bottom: 276px;
  position: relative;
}

.preshkit_bg {
  position: absolute;
  right: 0;
  top: -50px;
  width: 286.56px;
}

.PressKit_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 48px;
}

.PressKit_main_section .tabs_div .nav-tabs {
  margin: 0 !important;
  margin: auto !important;
  padding-bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 40%; */
}

.PressKit_main_section .tabs_div {
  margin-bottom: 0;
}

.presh_kit_img_box {
  margin-bottom: 32px;
}
.presh_kit_img_box .preshkit_products_box {
  margin-bottom: 0px;
  position: relative;
}
.presh_kit_img_box img {
  max-width: 100%;
  height: 100%;
  position: relative;
}
.overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  border-radius: 15px;
  opacity: 0;
  transition: 0.1s all ease-in-out;
}
.presh_kit_img_box:hover .overlay,
.preshkit_products_box:hover .overlay {
  opacity: 1;
}
.Brand4_img {
  max-height: 355px;
  width: 100%;
}

.preshkit_products_box {
  position: relative;
  margin-bottom: 32px;
}
.preshkit_products_box_leadership_img {
  width: 100%;
  height: 390px !important;

  height: 100%;
  border-radius: 18px;
}
.preshkit_products_box {
  max-height: 390px;
  width: 100%;
}
.preshkit_products_box .preshkit_products_box_leadership_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.product4_img {
  height: 390px !important;
}

.preshkit_products_box_content_btns {
  top: 20px;
  right: 23px;
  position: absolute;
}

.preshkit_products_box_content h2 {
  bottom: 27px;
  left: 16px;
  position: absolute;

  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #f1f1fa;
}

.eye_icons {
  margin-right: 26px;
}

.preshkit_view_all_btn_div {
  text-align: center;
  margin-top: 24px;
}

.preshkit_view_all_btn_div a {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #0264ff;
  text-decoration: none;
}

.blog_para_for_mob {
  display: none;
}

.FeaturedVideos_main_section_slider {
  background: #02020a;
}

.FeaturedVideos_main_section_slider .carousel-indicators {
  bottom: -10%;
}

.FeaturedVideos_main_section_slider {
  display: none;
}
.newsroom_mob_img {
  display: none;
}
.news_content_left_side_main img {
  border-radius: 32px;
  width: 100%;
  max-height: 530px;
  object-fit: cover;
}
.featured_image_short {
  width: 100%;
  height: 226px !important;
  object-fit: cover;
}
.side_short_des {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.morestories_news_slider {
  margin-top: 30px !important;
}

.more_stories_main_section .react-multi-carousel-dot button {
  background: #fcfcfc;
  height: 8px !important;
  margin-right: 8px !important;
  opacity: 0.5;
  width: 8px !important;
  border: none;
}
.more_stories_main_section .react-multi-carousel-dot--active button {
  background: #fcfcfc !important;
  height: 8px !important;
  opacity: 1 !important;
  width: 26.67px !important;
}
.more_stories_main_section .react-multiple-carousel__arrow--right,
.more_stories_main_section .react-multiple-carousel__arrow--left {
  display: none;
}
@media screen and (max-width: 1200px) {
  .newroom_ring {
    right: 0;
  }

  .FeaturedVideos_bg {
    top: 143px;
    width: 193px;
  }

  .preshkit_bg {
    width: 150px;
    top: 50px;
  }
  .PressKit_main_section .tabs_div .nav-tabs {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .news_content_left_side_main {
    margin-bottom: 36px;
  }

  .blog_para_for_mob {
    display: block;
  }

  .news_content_right_side_main_single_none {
    display: none;
  }
  .more_stories_main_section_mob_none {
    display: none;
  }
  .FeaturedVideos_main_display_none {
    display: none;
  }
  .FeaturedVideos_main_section {
    padding-bottom: 1px;
  }
  .FeaturedVideos_main_section_slider {
    display: block;
  }
  .FeaturedVideos_main_section_slider .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    margin-left: 8px !important;
  }
  .FeaturedVideos_main_section_slider .carousel-indicators .active {
    width: 26px !important;
  }
}

@media screen and (max-width: 768px) {
  .newroom_ring {
    top: 270px;
    width: 50px;
  }

  .newsroom_hero_main_section_headings h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    padding-bottom: 24px;
  }

  .newsroom_hero_main_section {
    padding-top: 137px;
  }

  .newsroom_hero_main_section .tabs_div .nav-tabs {
    width: 100%;
    padding-bottom: 36px;
  }

  .news_content_left_side_content_main h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .news_content_left_side_content_main h4 {
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0;
  }

  .news_by_and_date_div span {
    font-size: 18px;
    line-height: 24px;
  }

  .news_content_left_side_content_main p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .more_stories_main_headings h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
  }

  .more_stories_single_box h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .more_stories_single_box {
    margin-bottom: 28px;
  }

  .FeaturedVideos_main_section_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 48px;
  }

  .FeaturedVideos_main_section {
    padding-top: 58px;
  }

  .FeaturedVideos_bg {
    top: 28px;
    width: 80px;
  }

  .PressKit_main_section {
    padding-top: 80px;
  }

  .PressKit_main_headings h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 16px;
  }

  .preshkit_bg {
    width: 90px;
  }

  .PressKit_main_section .tabs_div .nav-tabs {
    width: 100%;
    text-align: center;
    padding-bottom: 36px !important;
  }

  .preshkit_products_box {
    margin-bottom: 16px;
  }

  .preshkit_view_all_btn_div {
    margin-top: 8px;
  }

  .PressKit_main_section {
    padding-bottom: 158px;
  }
  .nav-tabs .nav-item {
    min-width: auto !important;
  }
  .newsroom_mob_img {
    display: block;
  }
  .newsroom_desktop_img {
    display: none;
  }
  .product4_img {
    height: auto !important;
  }
  .PressKit_main_section .tabs_div .nav-tabs {
    justify-content: center;
    overflow: scroll;
  }
  .PressKit_main_section .tabs_div .nav-tabs::-webkit-scrollbar {
    width: 0px;
  }
}
.more_stories_single_box h3 a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .leadership_preshkit_products_box {
    width: 100%;
    height: 250px;
  }
  .leadership_preshkit_products_box img {
    width: auto;
    height: 100% !important;
    object-fit: cover;
  }
}
