* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.body_overflow_hide {
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.overflow_hidden {
  overflow: hidden;
}
@font-face {
  font-family: 'Lufga600';
  src: url('../lufga-cufonfonts/LufgaBold.ttf');
}

@font-face {
  font-family: 'Lufga400';
  src: url('../lufga-cufonfonts/LufgaRegular.ttf');
}

@font-face {
  font-family: 'Lufga500';
  src: url('../lufga-cufonfonts/LufgaMedium.ttf');
}

.pos_rel {
  position: relative;
}

.pos_abs {
  position: absolute;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

video {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.both_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px !important;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  border-radius: 40px;
}

.theme_border_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  border-radius: 40px;
  padding: 2px !important;
}
.theme_border_btn_div {
  background: transparent;
  border-radius: 40px;
  padding: 10px 20px !important;
  background-color: #000;
}
/* ===============Header Section================ */
.header_bg {
  background: rgba(241, 241, 250, 0.04);
  background-color: #0c0c14;
  backdrop-filter: blur(7.5px);
  position: fixed;
  top: 0;
}

.header_menus a {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fcfcfc;
  transition: 0.3s all ease-in-out;
  margin-left: 38px;
  text-decoration: none;
}
.header_logo {
  width: 120px;
}
.nav-link {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fcfcfc;
  transition: 0.3s all ease-in-out;
  margin-left: 54px;
}

.header_menus a:hover {
  color: #17e884;
}
.nav_active {
  color: #17e884 !important;
}
.navbar-toggler-icon {
  background-image: url('../imgs/menu-toggle-btn.webp') !important;
  background-size: auto !important;
}

.navbar-toggler:focus {
  border: none !important;
  box-shadow: none !important;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}
.login_btn {
  margin-left: 20px;
  font-size: 16px;
  font-family: Lufga600 !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px !important;
  text-transform: uppercase;
  color: #fcfcfc !important;
}

/* ============Main Hero Section================ */

.heder_main_div {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999999;
  position: fixed;
}

.hero_video_section {
  position: relative;

  z-index: 999;
  position: relative;
}

.hero_video_section video {
  height: auto;
  width: 100%;
}

.hero_content_div {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 80%;
  margin: auto;
}

.hero_content_div h1 {
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fcfcfc;
  font-family: 'Lufga600';
  margin-bottom: 30px;
}

.hero_content_div h1 span {
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero_content_div p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  font-family: 'Lufga400';
  opacity: 0.7;
  width: 55%;
  margin: auto;
}

.hero_bg_images img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 13%;
  height: auto;
  z-index: 99;
}

.hero_down_arrow {
  width: 100%;
  position: absolute;
  bottom: 5%;
}

.hero_down_arrow_icon {
  position: absolute;
  left: 50%;
  color: #fcfcfc;
}

.bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-15px);
  }
}

/* ============battery Section================ */

.battery_main_section {
  background-color: #000;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  z-index: 9;
  position: relative;
}
.battery_main_section .carousel-indicators {
  display: none;
}
.battery_content_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.battery_main_section video {
  width: 100%;
  max-width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
}

.uploading_file_name {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 0;
}
.error_name {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: red;
  margin-bottom: 0;
}
.check_btn {
  font-size: 20px;
  margin-left: 20px;
}
.d_flex_centter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.battery_content_div h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  padding-right: 50px;
  color: #fcfcfc;
  margin-bottom: 40px;
}

.battery_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  border-radius: 40px;
  padding: 2px !important;
  width: 161px;
}

.battery_btn_div {
  background: transparent;
  border-radius: 40px;
  padding: 10px 20px !important;
  background-color: #000;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #f1f1fa;
}

/* ============One_stop_solution_section======== */

.One_stop_solution_section {
  background: #02020a;
  position: relative;
  padding: 120px 0;
}

.One_stop_solution_section h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  width: 100%;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 56px;
  text-transform: capitalize;
}

.One_stop_solution_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 100px;
  padding-right: 80px;
}
.One_stop_solution_content_small_box h3 {
  padding: 2px 10px;
  background: #1b1b22;
  border-radius: 4px;

  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-bottom: 16px;
  display: inline-block;
}

.One_stop_solution_content h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 14px;
}

.One_stop_solution_content p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #cbcbcb;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.One_stop_solution_section .carousel-indicators {
  top: 0%;
  left: auto;
  right: -10% !important;
  display: flex;
  flex-direction: column;
}

.carousel-indicators [data-bs-target] {
  width: 6px !important;
  height: 14px !important;
  margin: 5px 0 !important;
  border: none !important;
  border-radius: 10px !important;
}

.carousel-indicators .active {
  height: 40px !important;
}

.Learn_More_btn {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #0264ff;
  display: inline-block;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 20px;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  text-decoration: none !important;
}

.Learn_More_btn .learn_arrow {
  display: none;
  margin-left: 8px;
}

.Learn_More_btn:hover .learn_arrow {
  display: flex;
}

.One_stop_solution_bg_img .one {
  position: absolute;
  left: 0;
  top: -15px;
  z-index: 9999;
}

.One_stop_solution_bg_img .two {
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 80px;
}

/* ============video section======== */

.video_section_main .carousel-indicators {
  display: none;
}
.video_section_main video {
  transform: scale(1.02);
}
.video_section_main_div {
  position: relative;
  text-align: center;
}

.video_section_inner_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.video_section_inner_div h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #ffffff;
}

.video_section_inner_div p {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

/* ============Our Tech section======== */

.our_tech_main_section {
  background: #02020a;
  position: relative;
  padding: 120px 0;
}

.tech_ring_img img {
  position: absolute;
  top: 35px;
  right: 230px;
}

.our_tech_main_white_section {
  padding: 48px 60px;
  background: linear-gradient(
    290.14deg,
    #e2e9f3 23.54%,
    rgba(218, 247, 233, 0.94) 81.15%
  );
  border-radius: 24px;
  z-index: 11;
  position: relative;
}

.our_tech_main_headings_div {
  text-align: center;
  margin-bottom: 56px;
}

.our_tech_main_headings_div h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0f0f0f;
}

.our_tech_main_headings_div p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #252527;
  width: 60%;
  margin: 16px auto 24px auto;
}

.Explore_our_technology_btn {
  display: inline-block;
  align-items: center;
  padding: 1px;
  border-radius: 100px;
  background: linear-gradient(to right, #0264ff, #17e884);
  transition: 0.3s all ease-in-out;
}

.Explore_our_technology_btn button {
  border: none;
  outline: none;
  background: none;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0f0f0f;
  padding: 16px 24px;
  border-radius: 100px;
  background: linear-gradient(
    290.14deg,
    #e2e9f3 23.54%,
    rgba(218, 247, 233, 0.94) 81.15%
  );
  transition: 0.3s all ease-in-out;
}

.Explore_our_technology_btn .learn_arrow {
  font-size: 24px;
  color: #000;
  padding-top: 0;
  margin-top: -3px;
  margin-left: 5px;
  display: none;
  transition: 0.3s all ease-in-out;
}

.Explore_our_technology_btn button:hover .learn_arrow {
  display: inline-block;
}

.tech_single_box {
  padding: 16px 16px 32px;
  background: rgba(255, 255, 255, 1) !important;
  cursor: pointer;
  border-radius: 24px;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.tech_single_box_border {
  background: rgba(255, 255, 255, 1);
  border-radius: 26px;
  padding: 2px;
  border: 1px solid #d9d9d9;
  transition: 0.3s all ease-in-out;
}
.tech_single_box_border a {
  text-decoration: none;
}
.tech_img_inner {
  overflow: hidden;
  border-radius: 18px;
  transition: 0.3s all ease-in-out;
}

.tech_single_box_border:hover {
  padding: 2px;
  background: linear-gradient(to right, #0264ff, #17e884);
}

.tech_single_box_border:hover img {
  transform: scale(1.05);
  overflow: hidden;
}

.tech_single_box_content h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #252527;
  opacity: 0.6;
  margin-top: 7px;
}

.tech_single_box_content h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin-top: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tech_single_box_content p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252527;
  opacity: 0.6;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tech_single_box img {
  border-radius: 18px;
  width: 100%;
  height: 266px;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}

.tech_read_more_btn {
  position: absolute;
  right: 32px;
  top: 44px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.tech_read_more_btn a {
  text-decoration: none;
  background-color: #0264ff;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 30px;
  transition: 0.3s all ease-in-out;

  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  padding: 16px 24px;
  border-radius: 100px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #0264ff;
}
.tech_read_more_btn img {
  width: 14px;
  height: 14px;
  border-radius: 0;
}
.tech_read_more_btn a:hover {
  text-decoration: none;
  color: #fff;
}
.tech_single_box:hover .tech_read_more_btn {
  display: block;
  opacity: 1;
  z-index: 999;
}

.tech_read_more_btn .learn_arrow {
  font-size: 24px;
  color: #fff;
  padding-top: 0;
  margin-top: -3px;
  margin-left: 5px;
}

/* ============testimonail section======== */

.testimonail_section {
  padding: 80px;
  padding-top: 0;
  background: #02020a;
  position: relative;
}

.testimonail_section_heading_div h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.test_bg_img {
  position: absolute;
  left: 0;
  top: -100px;
  width: 420px;
}
.driver_small_img {
  width: 71px;
  height: 71px;
}
.testimonail_section .carousel-indicators {
  top: 0%;
  left: auto;
  right: -15% !important;
  display: flex;
  flex-direction: column;
}

.testimonail_section .carousel-indicators [data-bs-target] {
  width: 6px !important;
  height: 20px !important;
  margin: 5px 0 !important;
  border: none !important;
  border-radius: 10px !important;
}

.testimonail_section .carousel-indicators .active {
  height: 60px !important;
}

.testimonail_inner_main {
  margin-top: 142px;
}

.testi_content_div {
  position: relative;
}

.test_test_img {
  position: relative;
  z-index: 9;
}
.translate_btn {
  position: absolute;
  top: 14px;
  /* left: 0; */
  z-index: 99;
  right: 24%;
}
.translate_btn button {
  display: inline-block !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}
.testi_text_div p {
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  width: 65%;
  margin: auto;
  margin-left: 11%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 99;

  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #fcfcfc;
}

.testi_diver_name_div {
  position: absolute;
  top: 160px;
  right: 25%;
  z-index: 99;
}

.testi_diver_name_div h3 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f1f1fa;
  margin-bottom: 0;
  padding-bottom: 0;
}

.testi_diver_name_div h4 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #bbbbbc;
}

.driver_img {
  margin-top: -220px;
}

.test_side_single_div_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test_side_single_div_inner {
  padding: 16px;
  background: #17282c;
  box-shadow: 0px 0px 80px rgba(23, 232, 132, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.test_side_single_div {
  border-radius: 16px;
  width: 422px;
  margin: auto;
  margin-bottom: 32px;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
  border: 1px solid #bbbbbc;
}

.test_side_single_div_inner img {
  margin-right: 16px;
}

.test_side_single_div_inner h3 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #fcfcfc;
}

.test_side_single_div_inner h4 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #bbbbbc;
}

.testi_content_div iframe {
  border-radius: 12px;
}

.testimonail_inner_main .active .test_side_single_active {
  width: 100%;
  width: 490px;
  opacity: 1;
  padding: 2px;
  background: linear-gradient(to right bottom, #0264ff, #17e884);
  border: none;
}

/* ============partnership section======== */

.partnership_main_section {
  background: #02020a;
  padding: 120px 0;
  position: relative;
}

.partnership_mob_section {
  display: none;
}

.partnership_right_arrow {
  position: absolute;
  top: 0;
  right: 0;
}

.partnership_bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.partnership_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.partnership_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  margin-bottom: 56px;
  width: 70%;
}

.partnership_main_inner_box {
  padding: 64px 60px;
  background: linear-gradient(
    89.88deg,
    rgba(2, 100, 255, 0.1) 0.07%,
    rgba(23, 232, 132, 0.1) 118.91%
  ) !important;
  border-radius: 32px;
  position: relative;
}

.partnership_single_box {
  padding: 54px 32px;
  padding-right: 73px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 32px;
  position: relative;
  margin-bottom: 24px;
  transition: 0.3s all ease-in-out;
}

.high_return_img {
  position: absolute;
  right: 60px;
  top: -55px;
  width: 200px;
}

.partnership_single_box_content h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  letter-spacing: 0.005em;
  color: #bbbbbc;
  transition: 0.3s all ease-in-out;
}

.partnership_single_box_content p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-top: 16px;
}

.partnership_single_box_content_blue_btn {
  margin-top: 49px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
}
.partnership_single_box_content_blue_btn a:hover {
  text-decoration: none;
  color: #fcfcfc;
}
.partnership_single_box_content_blue_btn a {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fcfcfc;
  padding: 16px 24px;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);

  border-radius: 100px;
  border: none;
  text-decoration: none;
}
.partnership_single_box_content_blue_btn a:hover {
  text-decoration: none;
  color: #fcfcfc;
}
.partnership_single_box_content_blue_btn .learn_arrow {
  display: none;
  margin-left: 8px;
  width: 14px;
  height: 14px;
  margin-top: -5px;
}

.partnership_single_box:hover .learn_arrow {
  display: inline-block;
}

.partnership_single_box_content_green_btn a {
  color: #17e884;
  background: none;
  color: #17e884;
  border: 1px solid;
  transition: 0.3s all ease-in-out;
}

.partnership_single_box:hover .partnership_single_box_content h1 {
  background: linear-gradient(89.88deg, #0264ff, #17e884);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.partnership_single_box:hover {
  background: rgba(255, 255, 255, 0.1);
}

.partnership_single_box:hover .partnership_single_box_content_green_btn a {
  background-color: #17e884;
  color: #000;
}

.partnership_single_box:hover .partnership_single_box_content_green_btn a {
  background: linear-gradient(89.88deg, #0218ff 0.07%, #17e884 118.91%);
  color: #fff;
  border: 0;
}

.mob_partnership_single_box_border {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  padding: 2px;
  border-radius: 32px;
  position: relative;
  margin-bottom: 24px;
  transition: 0.3s all ease-in-out;
}

.partnership_mob_section .partnership_single_box {
  background: #151f2b;
  margin-bottom: 0;
}

/* ============Our Footprint section======== */

.our_footprint_main_section {
  background-image: url('../imgs/Map.png');
  background-position: top;
  background-size: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #04040b;
  position: relative;
}
.bg_dark {
  background: #04040b;
}
.mt_b_34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}
.our_footprint_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  padding-top: 83px;
}

.our_footprint_main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding-bottom: 637px;
}

.our_footprint_inner_main_box {
  padding: 24px 32px;
  background: rgba(0, 0, 0, 0.26);
  border-radius: 32px;
  margin-top: -465px;
  position: relative;
  z-index: 99;
}
.our_footprint_inner_main_box .new_form_section_main {
  background-color: transparent;
}
.our_footprint_inner_main_box h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.footprint_form label {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.footprint_form input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.footprint_form .form-select {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);

  border-radius: 0 !important;
  padding: 23px 8px 5px !important;
  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
}

.footprint_form .form-select option {
  background: rgb(19, 19, 19) !important;
}

.form_text_area {
  margin-top: 54px !important;
}

.form_radio_btn {
  margin-bottom: 35px;
}

.form_radio_btn h2 {
  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 0;
  letter-spacing: 0.016em;
  color: #ffffff;
  margin-top: 48px;
  margin-bottom: 38px;
}

.form_radio_btn label {
  font-family: 'Lato', sans-serif !important;

  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 27px;
  cursor: pointer;
}

.form_radio_buttons .radio_buttons {
  color: #fff;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.form_radio_buttons .btn:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.form_radio_buttons .radio_buttons input {
  margin-right: 1px;
  background: rgba(255, 255, 255, 0.7);
}

.radio_btns_contents .input_box {
  padding-bottom: 30px;
}

.radio_btns_contents .para {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  margin-top: 32px;
}

.radio_btns_contents h3 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  margin-top: 56px;
  margin-bottom: 14px;
}

.form_submit_btn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
}
.form_submit_btn_flex_end {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: end;
}
.form_submit_btn button {
  border: none;
  text-decoration: none;
  padding: 16px 24px;
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  border-radius: 100px;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.8s all ease-in-out;
}

.form_submit_btn button .learn_arrow {
  width: 14px;
  height: 14px;
  display: none;
  margin-left: 8px;

  transition: 0.8s all ease-in-out !important;
}

.form_submit_btn button:hover .learn_arrow {
  display: inline-block;
  transition: 0.3s all ease-in-out !important;

  margin-left: 8px;
}

/* ============FAQ section======== */

.faq_main_section {
  background: #02020a;
  position: relative;
  padding-top: 87px;
  padding-bottom: 85px;
}

.faq_bg_img {
  position: absolute;
  top: -20px;
  left: -300px;
  z-index: 90;
}

.footer_bg {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
}

.faq_bg_img1 {
  position: absolute;
  top: 70%;
  left: 0;
  transform: translate(0, -50%);
}

.faq_bg_img2 {
  position: absolute;
  top: 70%;
  right: 0;
  z-index: 1;
  transform: translate(0, -50%);
}

.faq_bg_img3 {
  position: absolute;
  top: 700px;
  right: 0;
  z-index: 1;
  transform: translate(0, -50%);
}

.faq_main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.faq_sibgle_box {
  padding: 20px !important;

  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  margin-bottom: 24px;
  border: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button {
  padding: 0 !important;
  width: 100%;
  font-size: 41rem !important;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;

  font-family: 'Lufga400' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  letter-spacing: -0.01em !important;
  color: #fcfcfc !important;
}

.faq_sibgle_box p {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin-top: 18px;
}

.faq_sibgle_box h2 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 0;
}

.accordion-body {
  padding: 0 !important;
}
.accordion-button::after {
  background-image: url('../imgs/arrow-down.png') !important;
  background-size: 12px !important;
  background-position: center !important;
}
.faq_sibgle_box ul {
  padding-left: 14px;
}

.faq_sibgle_box ul li {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
}

.tabs_div {
  margin-bottom: 100px;
  position: relative;
  z-index: 99;
}

.tabs_div .nav-tabs {
  justify-content: space-between;
  justify-content: center;
  border-bottom: 0 !important;
  margin-bottom: 60px !important;
  margin-top: 64px !important;
}

.tabs_div .nav-item button {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #bbbbbc;

  border-radius: 72px;
  white-space: nowrap;
}

.tabs_div .nav-tabs .nav-link.active {
  background-color: transparent !important;
  color: #17e884;
  border: 1px solid #17e884;
  border-radius: 72px;
  padding: 8px 16px;
}

.tabs_div .nav-item button:hover {
  background-color: transparent !important;
  color: #17e884;
  border: 1px solid #17e884;
  border-radius: 72px;
  padding: 8px 16px;
}
.tabs_div .nav-item button:hover {
  border: none;
  border: 1px solid #02020a;
}
.tabs_div .nav-tabs .nav-link.active:hover {
  border: 1px solid #17e884;
}
.faq_slider_buttons_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.faq_slider_buttons {
  border: 1px solid #fcfcfc;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
}
.faq_slider_buttons:focus {
  border: 1px solid #17e884;
}
.faq_slider_buttons:focus .slider_arrow {
  color: #17e884;
}

.faq_slider_buttons .slider_arrow {
  color: #ffffff;
}

.faq_slider_buttons_div .right_gap {
  margin-right: 20px;
}

/* ============Footer section======== */
.footer_main_section {
  position: relative;
  z-index: 9;
}
.footer_main_section_inner {
  padding-top: 48px;
  background: #02020a;
}

.footer_logo {
  max-width: 112px;
}
.footer_main_section_border {
  background: linear-gradient(89.88deg, #0264ff, #17e884);
  height: 1px;
}

.footer_col img {
  margin-bottom: 18px;
  max-width: 112px;
}

.footer_col a,
.footer_col button {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.footer_col a:hover,
.footer_col button:hover {
  color: #17e884;
}
.footer_contact_details_single {
  display: flex;
}
.footer_contact_details_single span {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 5px;
}
.footer_col p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer_col h3 a {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #fcfcfc;
  margin-bottom: 12px;
}
.footer_contact_btn {
  display: inline-block !important;
  margin-top: 56px;
  margin-left: 0;
  color: #fff !important;
}
.footer_contact_btn a {
  color: #fff !important;
}
.copy_right_text {
  font-family: 'Lato', sans-serif;
  background: #02020a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f2f2f2;
  padding-top: 55px !important;
  padding-bottom: 48px !important;
  text-align: center;
}

/* ============About page======== */

.about_hero_main_section {
  background: #02040c;
  padding-top: 186px;
  margin-bottom: 0;
  position: relative;
}

.about_hero_main_section .main_heading {
  margin-bottom: 0;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  letter-spacing: 0.005em;
  color: #fcfcfc;
  text-align: center;
}
.partner_hero {
  margin: auto;
  width: 80%;
}

.about_hero_main_section .main_heading span {
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about_hero_main_section .main_para {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: normal;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  margin-bottom: 0;
  width: 60%;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 45px;
  opacity: 0.699999988079071;

  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.about_hero_main_section .arrow_down_btn {
  padding-bottom: 67px;
}

.about_bg_fade1 {
  position: absolute;
  right: 75.35%;
  left: 0;
  top: 27.27%;
  width: 355px;
  height: 265.56px;
}

.about_bg_fade2 {
  position: absolute;
  right: 10%;

  top: 110px;
  width: 470px;
  height: 344px;
}

.about_arrow {
  position: absolute;
  left: 0;
  width: 12%;
  left: 202px;
  top: -184px;
}

/* ============Our story section================ */

.Our_Story_main_section {
  background: #02040c;
  position: relative;
}

.our_story_container {
  background: #1b1b22;
  box-shadow: 0px 4px 4px rgba(53, 52, 52, 0.4);
  border-radius: 32px;

  padding: 38px 33px 49px;
}

.our_story_content_side {
  margin-left: -50px;
}

.our_story_content_side h1 {
  padding-bottom: 13px;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.our_story_content_side p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #bbbbbc;
  opacity: 0.7;
  margin-bottom: 25px;
}

.our_story_content_side p:last-child {
  margin-bottom: 0;
}

.our_story_img {
  position: relative;
}

.our_story_img img {
  position: absolute;
  width: 620px;
  left: -124px;
  top: 90px;
  border-radius: 32px;
}

/* ============Our story section================ */

.Our_Team_section {
  background: #02040c;
  padding: 120px 0;
  position: relative;
}

.team_bg {
  position: absolute;
  right: 0;
  bottom: 200px;
}

.Our_Team_section .main_heading {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 56px;
}

.team_single_box {
  background: #202027;
  border-radius: 24px;
  padding: 16px;
  min-height: 575px;
}

.team_single_box_border {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
  padding: 1.3px;
  border-radius: 24px;
}

.team_single_box_inner {
  display: flex;
}

.team_single_box_inner .img_side {
  width: 60%;
}

.team_single_box_inner .content_side {
  width: 50%;
}

.team_single_box h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 4px;
}

.team_single_box h4 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #f1f1fa;
  margin-bottom: 0;
  padding-bottom: 0;
}

.team_img {
  width: 270px !important;
  height: 431px !important;
  margin-bottom: 18px;
}

.team_name_div_flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 85%;
}

.team_name_div_flex img {
  width: 25.87px;
  height: 22px;
}

.team_single_box p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #f1f1fa;
}

.team_small_box_border {
  margin-top: 64px;
  background: #212227;
  padding: 2px;
  border-radius: 24px;
  transition: 0.9s all ease-in-out;
  min-height: 505px;
}

.team_small_box_border:hover {
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
}

.team_small_box {
  padding: 16px;
  background: #212227;
  border-radius: 24px;
  transition: 0.9s all ease-in-out;
  min-height: 505px;
}
.team_small_box_img {
  height: 200px;
  width: 100%;
}
.team_small_box img {
  filter: grayscale(100%);
}
.team_small_box_border:hover .team_small_box img {
  filter: grayscale(0%);
}
.team_small_box .team_name_div_flex img {
  filter: grayscale(0%);
}
.team_small_box h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-top: 18px;
}

.team_small_box h4 {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  margin-bottom: 18px;
}

.team_small_box p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fcfcfc;
  margin-bottom: 0;
}

.team_small_box .team_name_div_flex {
  width: 100%;
  align-items: flex-start;
}

.team_small_box .team_name_div_flex img {
  margin-top: 22px;
}

.team_section_mobile {
  display: none;
}

/* ============Icons Box section================ */

.box_icons_main_section {
  background: #02040c;
  position: relative;
  overflow: hidden;
}

.box_icons_boxes_main {
  border-radius: 32px;
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  padding: 2px;
  width: 100%;
  height: 600px;
}
.box_icons_boxes_main video {
  border-radius: 32px;
  width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box_icons_boxes {
  text-align: center;
}

.box_icons_boxes .icon_img {
  max-width: 95px;
}

.box_icons_boxes h3 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25.5434px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 25px;
}

/* ============Team Work section================ */

.team_work_main_section {
  background: #02040c;
  position: relative;
  padding-top: 158px;
  padding-bottom: 126px;
}

.team_work_bg {
  position: absolute;
  left: 0;
  top: 150px;
  width: 20%;
}
.team__img {
  width: 405px;
  height: 281px !important;
}
.team_work_main_section .main_headings {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  width: 60%;
  margin: auto;
  margin-bottom: 42px;
}

.team_work_main_section img {
  margin-bottom: 32px;
}

.team_work_main_section .carousel-indicators {
  bottom: -30px;
}

.team_work_main_section .carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  background: #fcfcfc;
  opacity: 0.5;
  margin-right: 8px !important;
}

.team_work_main_section .carousel-indicators .active {
  width: 26.67px !important;
  height: 8px !important;
  background: #fcfcfc !important;
  opacity: 1 !important;
}

.team_work_mobile_slider {
  display: none;
}

/* ============join our section================ */

.join_our_main_section {
  background: #02040c;
  position: relative;
  padding-top: 100px;
  padding-bottom: 175px;
}

.join_our_main_section .main_headings {
  text-align: center;
}

.join_our_main_section .main_headings h1 {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 24px;
}

.join_our_main_section .main_headings p {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  opacity: 0.7;
  margin-bottom: 32px;
}

.join_our_main_section .tabs_div_inner {
  width: 70%;
  margin: auto;
  margin-bottom: 80px !important;
}

.join_our_small_boxes {
  padding: 32px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 32px;
  border: 2px solid #16181f;
  transition: 0.3s all ease-in-out;
  margin-bottom: 32px;
}

.join_our_small_boxes:hover {
  border: 2px solid #005ee1;
}

.join_our_small_boxes h3 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #bbbbbc;
  transition: 0.3s all ease-in-out;
}

.join_our_small_boxes:hover.join_our_small_boxes h3 {
  background: -webkit-linear-gradient(176deg, #0264ff 15.07%, #17e884 118.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.join_our_small_boxes h4 {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #bbbbbc;
  margin: 16px 0 32px 0;
}

.join_our_small_boxes .a {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #0264ff;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.join_our_small_boxes:hover .a {
  color: #0264ff !important;
}
.link_arrow {
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  display: none;
  transition: 0.3s all ease-in-out;
}

.download_job_btn img {
  width: 14px;
  margin-top: -4px;
  margin-left: 8px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.download_job_btn:hover img {
  opacity: 1;
}
.join_our_small_boxes a:hover .link_arrow {
  display: inline-block;
}

.join_form_section_border {
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  border-radius: 32px;
  padding: 2px;
}

.join_form_section {
  padding: 48px 61px;
  background: #02020a;
  border-radius: 32px;
}

.join_form_section h4 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-bottom: 54px;
}

.footprint_form .join_form_field {
  margin-bottom: 48px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 5px;
  cursor: pointer !important;
}

.file_upload_btn {
  font-size: 20px;
  font-weight: bold;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer !important;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}

.file_uppload_icon {
  margin-left: 10px;
}

/* ============join our section================ */

.news_section_main {
  background: #02040c;
  position: relative;
  padding-bottom: 194px;
}

.news_bg_ring {
  position: absolute;
  right: 13.5%;
  top: -60px;
  width: 168px;
  height: 168px;
}

.news_section_main_inner {
  padding: 56px 0 83px 25px;
  background: linear-gradient(
    143.11deg,
    #e2e9f3 11.08%,
    rgba(218, 247, 233, 0.94) 36.26%
  );
  border-radius: 20px;
  position: relative;
  z-index: 99;
}

.news_section_main .main_headings {
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  text-align: center;
  margin-bottom: 83px;
}

.new_box_border {
  border-radius: 24px;
  background: #eaf5ef;
  padding: 2px;
  border: 1px solid #d9d9d9;
  transition: 0.3s all ease-in-out;
  margin-right: 32px;
}

.new_box_main {
  padding: 16px 16px 32px;
  background: #eaf5ef;
  border-radius: 22px;
  transition: 0.3s all ease-in-out;
  position: relative;
  min-height: 500px;
}

.new_box_border:hover {
  background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
  transition: 0.3s all ease-in-out;
}

.new_box_main img {
  margin-bottom: 16px;
  border-radius: 18px;
  height: 248px;
  width: 100%;
  object-fit: cover;
}

.new_box_main h3 {
  font-family: 'Lufga400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #252527;
  opacity: 0.6;
  margin-bottom: 16px;
}

.new_box_main h2 {
  font-family: 'Lufga500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #0f0f0f;
  margin-bottom: 8px;
}

.new_box_main p {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252527;
  opacity: 0.6;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.new_read_more {
  position: absolute;
  right: 32px;
  top: 44px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.new_read_more .read_more {
  text-decoration: none;
  text-decoration: none;
  background-color: #0264ff;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 30px;
  transition: 0.3s all ease-in-out;
  font-family: 'Lufga600';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  padding: 16px 24px;
  border-radius: 100px;
  background: #005ee1;
}

.new_read_more .read_more:hover {
  text-decoration: none;
}
.new_read_more .learn_arrow {
  margin-left: 8px;
  width: 14px;
  height: 14px;
  margin-bottom: 0;
  border-radius: 0;
}
.new_box_main:hover .new_read_more {
  opacity: 1;
}

.new_box_main:hover.new_box_main h3 {
  color: #005ee1;
}

.news_section_main .react-multiple-carousel__arrow {
  display: none !important;
}

.custom_drop_down_btn {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
  color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 0 !important;
  text-align: left !important;
  padding-left: 8px !important;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 148%;
  letter-spacing: 0.006em;
}

.custom_drop_down .dropdown-toggle::after {
  position: absolute !important;
  right: 10px !important;
  top: 50% !important;
  transform: translate(-50%);
}

.custom_drop_down_btn:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown-menu {
  width: 100%;

  background: #02020a !important;
  border: 2px solid #191920 !important;
  border-radius: 4px 4px 0px 0px !important;
}

.custom_drop_down .form-check {
  border-bottom: 1px solid #191920 !important;

  padding-left: 31px !important;
  padding-top: 6px;
  padding-bottom: 5px;
  padding: 6px;
  margin: 0;
}
.custom_drop_down .form-check:last-child {
  border-bottom: none !important;
}

.custom_drop_down label {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
  display: block;
  margin-left: 8px;
}

.custom_drop_down .form-check-input[type='checkbox'] {
  border: 2px solid #191920 !important;
  background-color: transparent;
}

.radio_buttons {
  display: flex !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

input[type='radio'] {
  height: 21px;
  width: 21px;
  min-height: 21px;
  min-width: 21px;
  background-color: transparent !important;
  appearance: none;
  border: 2px solid #ffffffb2 !important;
  border-radius: 50%;
}

input[type='radio']:checked {
  background-color: #17e884 !important;
  border: 2px solid #17e884 !important;
  appearance: auto !important;
}
#accent,
#AreaPartner,
#Franchise,
#RACEVault,
#Fleet,
#OEM,
#Other {
  accent-color: #17e884 !important;
}

/* keyboard navigation focus appearance */
.custom-radio:focus-visible {
  outline-offset: 0;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.custom_drop_down .form-check-input[type='checkbox'] {
  border-radius: 4px !important;
  box-shadow: none;
  padding: 5px !important;
  border: 1.5px solid #bbbbbc !important;
}
.custom_drop_down .form-check {
  transition: 0.3s all ease-in-out;
  margin-top: 0;
}
.custom_drop_down .form-check:hover {
  background-color: #252525;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url('../imgs/check.webp') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding: 5px !important;
  border: 1.5px solid #17e884 !important;
  background-size: 12px 8px;
}

.form-select option {
  font-family: 'Manrope', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #bbbbbc;
}

.form-select option:hover {
  background-color: #0e0e0e !important;
  color: #fff;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  transition: transform 2s cubic-bezier(0.02, 1.23, 0.79, 1.08);
  background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);

  filter: blur(40px);
  opacity: 0.5;
}

.custom_select_box_mui {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.006em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.carousel-item {
  transition: opacity 0.3s ease-in-out !important;
}
li {
  transition: opacity 0.3s ease !important;
}
.mui_input_label {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 148%;
  letter-spacing: 0.006em !important;
  color: rgba(255, 255, 255, 0.7);
}

.Error_modal .modal-content {
  background-color: rgb(195, 0, 0);
  border-radius: 10px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fcfcfc;
}

.success_modal .modal-content {
  background-color: rgb(0, 195, 72);
  border-radius: 10px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fcfcfc;
}

.tabs_scrolls {
  width: 500px;
  overflow: scroll;
}

.news_section_main .react-multi-carousel-dot-list {
  bottom: 0;
  left: -30px;
}
.news_section_main .react-multi-carousel-item {
  min-height: 590px;
}
.news_section_main .react-multi-carousel-dot button {
  border: none;
  background: #252527;
  opacity: 0.3199999928474426;
  height: 8px !important;
  width: 8px !important;
}

.news_section_main .react-multi-carousel-dot--active button {
  width: 26px !important;
  height: 8px !important;
  background-color: #252527 !important;
  opacity: 1;
}

input:visited {
  outline: none !important;
  background-color: red !important;
}
input {
  background-color: transparent !important;
  border: none;
}

input {
  background: none !important;
}
input {
  background-color: transparent !important;
}

.gradient_heading {
  background: -webkit-linear-gradient(
    360deg,
    rgba(2, 100, 255, 1),
    rgba(23, 232, 132, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* thank you page */

.thankyou_page_main_section {
  background: linear-gradient(0deg, #02020a 0%, #02020a 100%),
    linear-gradient(
      155deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(218, 246, 233, 0.09) 100%
    );
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.footer_bg1 {
  position: absolute;
  top: 100px;
  left: 0;
}
.footer_bg2 {
  position: absolute;
  top: 0;
  right: 0;
}
.thankyou_check {
  width: 92px;
  height: 92px;
}
.thankyou_page_main_section h1 {
  color: #fcfcfc;
  text-align: center;
  font-family: Lufga500;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.36px;
  margin-top: 32px;
}

.thankyou_page_main_section p {
  color: #fcfcfc;
  text-align: center;
  font-family: Lufga400;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.22px;
  margin-top: 16px;
  margin-bottom: 48px;
}
.back_to_home_btn {
  padding: 16px 24px;
  border-radius: 100px;
  font-family: Lufga600;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-decoration: none;
  color: #bbbbbc;
  background: linear-gradient(0deg, #02020a 0%, #02020a 100%),
    linear-gradient(
      155deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(218, 246, 233, 0.09) 100%
    );
  height: 53px;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s all ease-in-out;
}
.back_to_home_btn_outer {
  background: linear-gradient(82deg, #0264ff 0%, #17e884 100%);
  padding: 2px !important;
  border-radius: 100px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.back_to_home_btn:hover {
  text-decoration: none;
  color: #bbbbbc;
}
.back_to_home_btn .learn_arrows {
  opacity: 0;
}

.back_to_home_btn:hover .learn_arrows {
  opacity: 1;
  margin-left: 10px;
}

.section-scroll-link {
  position: relative;
  display: block;
  top: -120px;
  visibility: hidden;
}

.team_read_more_btn {
  background-color: transparent;
  border: none;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0264ff;
  margin-bottom: 0;
}
