@media screen and (max-width: 1640px) {
  .news_bg_ring {
    right: 60px;
  }
  .tech_ring_img img {
    right: 10px;
  }
  .about_arrow {
    left: 130px;
    top: -134px;
    width: 10%;
  }
  .join_our_main_section .tabs_div .nav-tabs {
    overflow: scroll;
    width: 100%;
    padding-bottom: 3px;
  }
  .join_our_main_section .tabs_div .nav-tabs::-webkit-scrollbar {
    width: 0px;
  }
}
@media screen and (max-width: 1440px) {
  .about_arrow {
    left: 80px;
    top: -134px;
    width: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .team_small_box {
    min-height: 560px;
  }
}
@media screen and (max-width: 1201px) {
  .team_single_box_inner .img_side {
    width: 30%;
  }
  .team_single_box_inner .content_side {
    width: 80%;
  }
  .team_img {
    width: 170px !important;
    height: auto !important;
  }
  .team_small_box h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) {
  .team_small_box {
    min-height: 530px;
  }

  .team_single_box {
    min-height: auto;
  }
  .team_name_div_flex img {
    height: auto;
  }

  .team_single_box_inner .content_side {
    width: 70%;
    margin-left: 20px;
  }
  .join_our_main_section .tabs_div_inner {
    width: 100%;
  }
  .box_icons_boxes_main {
    width: 100%;
    height: auto;
  }
  /* .team_single_box_inner .content_side {
    width: 100%;
  } */

  .team_single_box_border {
    margin-bottom: 64px;
  }

  .team_single_box_border:nth-child(2) {
    margin-bottom: 0px;
  }
  .testi_text_div p {
    width: 80%;
    font-size: 12px;
  }
  .translate_btn {
    right: 16%;
  }
  .testi_diver_name_div {
    top: 120px;
    right: 17%;
  }
  .testi_diver_name_div h3 {
    font-weight: 700;
    font-size: 9.171px;
    line-height: 13px;
  }
  .testi_diver_name_div h4 {
    font-weight: 400;
    font-size: 8.02463px;
    line-height: 11px;
  }
  .team__img {
    height: 245px !important;
  }
  .header_menus a {
    margin-left: 20px;
  }
  .battery_main_section img {
    height: auto;
  }
}
@media screen and (max-width: 1199px) {
  .team_small_box {
    min-height: auto;
  }
}
@media screen and (max-width: 1024px) {
  .team_single_box_inner .img_side {
    width: 25%;
  }
  .testi_text_div p {
    width: 82%;
    font-size: 12px;
    margin-left: 7%;
  }
  .testimonail_inner_main .active .test_side_single_active {
    width: 420px;
  }
  .test_side_single_div {
    width: 390px;
  }
  .footer_col a {
    display: none;
  }
  .footer_col .phone__email {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  .footer_col p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  .footer_col p br {
    display: none;
  }
  .footer_col address {
    margin-bottom: 40px;
  }
  .footer_col h3 a {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .footer_col h3 {
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #252527;
  }
  .copy_right_text {
    background: #1b1b22;
    margin: 0 !important;
    padding: 0 !important;
    padding: 10px 0 !important;
  }
  .team__img {
    height: 202px !important;
  }
  .team_small_box_img {
    height: auto;
  }
}
@media screen and (max-width: 992px) {
  .team__img {
    height: auto !important;
  }
}
@media screen and (max-width: 991px) {
  .header_menus {
    padding-bottom: 28px;
  }
  .login_btn {
    padding: 10px 20px;
    width: auto;
    height: 38px;
    background: linear-gradient(89.88deg, #0218ff 0.07%, #17e884 118.91%);
  }
  .header_menus a {
    margin-top: 20px;
    margin-left: 20px;
  }
  .header_bg {
    background-color: #000;
  }
  .navbar-nav {
    background: #1b1b22;
  }

  .form_radio_buttons a {
    min-width: 130px;
  }

  .btn-group {
    overflow: auto;
    width: 100%;
  }

  .testi_text_div p {
    width: 45%;
    top: 30px;
    left: -15px;
  }

  .testi_diver_name_div {
    position: absolute;
    top: 149px;
    right: 50%;
  }

  .testi_content_div {
    margin-bottom: 63px;
  }

  .team_single_box_inner .content_side {
    width: 73%;
  }

  .our_story_img img {
    position: initial;
    width: 100%;
    height: auto;
  }

  .our_story_content_side {
    margin-left: 0;
  }
  .team_single_box_inner .content_side {
    width: 67% !important;
  }
  .partnership_single_box_content h1 {
    font-size: 40px;
    line-height: 56px;
    background: linear-gradient(89.88deg, #0264ff, #17e884);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .Investment_money {
    width: 230px;
    left: -29px;
  }
  .video_section_main video {
    transform: scale(1.05);
  }

  .news_section_main .react-multi-carousel-dot-list {
    display: flex;
  }
}

@media screen and (max-width: 769px) {
  .partnership_main_section {
    display: none;
  }
  .our_footprint_main_headings p {
    padding-bottom: 350px;
  }
  .login_btn {
    font-size: 14px !important;
    font-style: normal !important;
    line-height: 18px !important;
    letter-spacing: 1.12px !important;
  }
  .thankyou_page_main_section {
    height: auto;
    padding-top: 150px;
    padding-bottom: 125px;
  }
  .thankyou_page_main_section h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.12px;
  }
  .thankyou_page_main_section p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    margin-bottom: 32px;
  }
  .thankyou_check {
    width: 62px;
    height: 62px;
  }
}

@media screen and (max-width: 768px) {
  .One_stop_solution_bg_img .two {
    width: 45px;
  }
  .hero_video_section {
    margin-top: 70px;
    height: 50vh;
    background: #000;
  }

  .hero_content_div h1 {
    font-size: 38px;
    line-height: 56px;
    margin-bottom: 8px;
  }

  .hero_content_div p {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
  }

  .hero_content_div {
    width: 100%;
    bottom: 5%;
  }

  .hero_down_arrow {
    display: none;
  }

  .hero_bg_images img {
    bottom: -35px;
  }

  .One_stop_solution_section {
    padding: 80px 0 100px 0;
  }
  .One_stop_solution_section h1 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 32px;
  }

  .One_stop_solution_bg_img .one {
    width: 20%;
  }

  .One_stop_solution_content {
    padding-left: 0;
    padding-right: 0;
    margin-top: 42px;
    display: block;
  }
  .One_stop_solution_content h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .One_stop_solution_content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .Learn_More_btn .learn_arrow {
    display: block;
  }

  .One_stop_solution_section .carousel-indicators {
    position: absolute !important;
    top: auto !important;
    right: 0 !important;
    bottom: -30% !important;
    left: 0 !important;
    z-index: 2 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-right: 15% !important;
    margin-bottom: 1rem !important;
    margin-left: 15% !important;
    list-style: none !important;
  }

  .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    margin-left: 8px !important;
  }

  .carousel-indicators .active {
    width: 26px !important;
    height: 8px;
    background: #fcfcfc !important;
    border-radius: 30px !important;
  }

  /* video section */
  .video_section_inner_div h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .video_section_inner_div p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .video_section_inner_div {
    width: 100%;
  }

  /* our tech section */
  .our_tech_main_section {
    padding: 80px 5px;
  }

  .tech_ring_img img {
    width: 60px;
    height: 60px;
    top: 48px;
    right: 12px;
  }

  .our_tech_main_headings_div h1 {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Lufga500';
    font-weight: 500;
  }

  .our_tech_main_headings_div p {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin: 16px auto 24px auto;
  }

  .Explore_our_technology_btn button {
    font-size: 14px;
    line-height: 18px;
    padding: 14px 16px;
  }

  .our_tech_main_headings_div {
    margin-bottom: 24px;
  }

  .our_tech_main_white_section {
    padding: 28px 25px;
  }

  .tech_single_box {
    padding: 15px;
  }
  .tech_single_box_border {
    padding: 1.5px;
    background: linear-gradient(to right, #0264ff, #17e884);
  }
  .tech_single_box_content h3 {
    background: linear-gradient(89.88deg, #0264ff 0.07%, #17e884 118.91%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 18px;
    line-height: 24px;
    margin-top: 15px;
  }

  .tech_single_box_content h2 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }

  .tech_single_box_content p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }

  .tech_single_box_border {
    margin-bottom: 24px;
  }

  .tech_read_more_btn {
    display: block;
    top: 27px;
    right: 27px;
    opacity: 1;
  }
  .tech_read_more_btn a {
    font-weight: 600;
    font-size: 10.0625px;
    line-height: 13px;
    padding: 10.0625px 11.5px;
  }
  .tech_read_more_btn img {
    width: 8px;
    height: 8px;
  }

  .tech_read_more_btn .learn_arrow {
    margin-left: 4px;
  }

  .tech_read_more_btn button {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.08em;
    padding: 10px 11px;
    margin-bottom: 0;
  }

  /* testimonail_section */
  .testimonail_section {
    padding: 0px 15px 60px 15px;
  }

  .testimonail_section .carousel-indicators {
    top: auto;
    left: 35%;
    transform: translate(-50%);
    right: auto !important;
    bottom: -5% !important;
    display: flex;
    flex-direction: row !important;
  }

  .testimonail_section .carousel-indicators [data-bs-target].active {
    width: 26px !important;
    height: 8px !important;
  }

  .testimonail_section .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    margin: 0;
    margin-right: 8px !important;
  }

  .testimonail_section_heading_div h1 {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Lufga500';
    font-weight: 500;
  }

  .testimonail_inner_main {
    margin-top: 43px;
  }

  .test_bg_img {
    width: 28%;
    top: 45px;
  }

  .testi_content_div {
    margin-bottom: 53px;
    text-align: center;
  }

  .testimonail_inner_main .active .test_side_single_active {
    width: 100%;
  }

  .test_side_single_div {
    width: 90%;
    margin-bottom: 19px;
  }
  .translate_btn {
    top: 9px;
    right: 26%;
  }
  .testi_text_div p {
    width: 49%;
    top: 57px;
    left: 50%;
    transform: translate(-50%);
    margin-left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .test_side_single_div_inner h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  .test_side_single_div_inner img {
    width: 32px;
  }
  .testi_diver_name_div {
    top: 160px;
    right: 25%;
  }
  .test_side_single_div_inner {
    padding: 7px;
  }
  /* partnership */
  .partnership_main_section {
    padding: 80px 0;
  }

  .partnership_main_inner_box {
    padding: 36px 10px;
  }

  .partnership_right_arrow {
    width: 98px;
    top: -10%;
  }

  .partnership_main_headings h1 {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Lufga500';
    font-weight: 500;
  }

  .partnership_main_headings p {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 0px;
  }

  .high_return_img {
    width: 101.92px;
    height: 50.2px;
    right: 17px;
    top: -23px;
    z-index: 99;
  }

  .partnership_mob_section .carousel-inner {
    padding-top: 36px;
  }

  .partnership_single_box_content_blue_btn .learn_arrow {
    display: inline;
  }

  .partnership_single_box_content_blue_btn {
    justify-content: flex-start;
  }

  .partnership_mob_section .carousel-indicators {
    bottom: -4%;
  }

  .partnership_single_box {
    padding: 24px 16px;
  }

  .partnership_single_box_content p {
    font-size: 14px;
    line-height: 22px;
  }

  .partnership_single_box_content_blue_btn button {
    font-size: 14px;
    line-height: 18px;
    padding: 14px 16px;
  }

  .partnership_mob_section {
    display: block;
    background-color: #000;
    padding-top: 80px;
    position: relative;
  }
  .partnership_right_arrow {
    top: 44px;
    width: 69.56px;
  }

  /* our footprint */
  .partnership_bottom {
    width: 96px;
    height: 38.03px;
  }

  .our_footprint_main_headings h1 {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Lufga500';
    padding-top: 80px;
    font-weight: 500;
  }

  .our_footprint_main_headings p {
    font-size: 18px;
    line-height: 24px;
    width: 85%;
    margin-top: 16px;
  }

  .our_footprint_inner_main_box {
    margin-top: -247px;
    padding: 16px;
  }

  .our_footprint_inner_main_box h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    width: 80%;
    margin: auto;
  }

  /* FAQ */
  .faq_main_section {
    padding-bottom: 30px;
  }

  .faq_bg_img {
    width: 123.03px;
    height: 85.06px;
    left: -60px;
  }

  .faq_bg_img3 {
    top: -100%;
    width: 32px;
  }

  .faq_main_headings h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .tabs_div .nav-item button {
    font-size: 12px;
    line-height: 16px;
  }

  .tabs_div .nav-tabs {
    margin-top: 36px !important;
    margin-bottom: 32px !important;
  }

  .faq_sibgle_box h2 {
    font-size: 16px;
    line-height: 24px;
  }

  .faq_sibgle_box p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
  }

  .faq_bg_img1,
  .faq_bg_img2 {
    display: none;
  }

  /* footer */
  .footer_col a {
    font-size: 12px;
    line-height: 22px;
  }

  .footer_main_section_inner {
    padding: 40px 4px 40px 4px;
  }

  .footer_bg {
    width: 125.87px;
    height: 72.04px;
  }

  .our_footprint_main_section {
    background-image: url('../imgs/Map.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000;
  }

  .form_submit_btn {
    justify-content: center;
  }

  .form_submit_btn button {
    font-size: 14px;
    line-height: 18px;
    padding: 11px 50px;
    margin-bottom: 0;
  }

  .faq_bg_img {
    top: 8%;
  }

  .faq_slider_buttons {
    width: 32px;
    height: 32px;
  }

  .faq_slider_buttons_div {
    margin-top: 32px;
  }

  .our_story_content_side h1 {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-top: 11px;
    font-family: 'Lufga500';
    font-weight: 500;
  }

  .our_story_content_side p {
    font-size: 16px;
    line-height: 24px;
  }

  .about_hero_main_section .main_heading {
    font-size: 38px;
    line-height: 56px;
    width: 100%;
  }

  .about_hero_main_section .main_para {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    padding-bottom: 23px;
    padding-top: 18px;
  }

  .about_arrow {
    width: 8%;
    left: 0;
    top: -50px;
  }

  .about_hero_main_section {
    padding-top: 137px;
  }

  .our_story_container {
    padding: 26px 19px 27px 16px;
  }

  .box_icons_boxes h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .logo_icon img {
    max-width: 25% !important;
    margin-top: 90px;
  }

  .box_icons_boxes .icon_img {
    max-width: 70px;
  }

  .team_work_main_section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .team_work_main_section .main_headings {
    font-family: 'Lufga500';
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    width: 92%;
    margin-bottom: 36px;
  }

  .team_work_main_section img {
    margin-bottom: 16px;
  }

  .join_our_main_section {
    padding-bottom: 80px;
    padding-top: 0;
  }

  .join_our_main_section .main_headings h1 {
    font-family: 'Lufga500';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 16px;
  }

  .news_bg_ring {
    width: 76px;
    right: 0;
    top: -28px;
  }

  .join_our_main_section .main_headings p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px !important;
  }

  .join_our_main_section .tabs_div .nav-tabs {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    justify-content: flex-start;
  }

  .faq_main_section .nav-tabs .nav-item {
    min-width: auto;
    margin-right: 0px;
    height: 36px;
  }
  .faq_main_section .tabs_div .nav-item button {
    margin-right: 32px;
  }
  .faq_main_section .tabs_div .nav-item button {
    padding: 8px 0;
  }
  .nav-tabs .nav-item {
    min-width: 120px;
    margin-top: 0px;
  }
  .join_our_main_section .nav-tabs .nav-item {
    min-width: auto;
    margin-top: 10px;
    margin-left: 10px;
  }
  .join_our_main_section .tabs_div {
    margin-bottom: 32px;
  }

  .join_our_small_boxes {
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 12px;
  }

  .join_our_small_boxes h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .join_our_small_boxes h4 {
    font-size: 14px;
    line-height: 22px;
    margin: 4px 0 16px 0;
  }

  .join_our_small_boxes a {
    font-size: 12px;
    line-height: 16px;
  }

  .join_form_section {
    padding: 32px 24px 24px 24px;
  }

  .join_form_section h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .footprint_form .join_form_field {
    margin-bottom: 20px;
  }

  .file_upload_btn {
    width: 100%;
  }

  .upload-btn-wrapper {
    display: block;
  }

  .join_our_main_section .form_submit_btn {
    justify-content: flex-end;
  }

  .news_section_main_inner {
    padding: 20px 20px;
  }

  .news_section_main {
    padding-bottom: 74px;
  }

  .news_section_main .main_headings {
    font-family: 'Lufga500';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 35px;
  }

  .new_box_border {
    margin-right: 10px;
    background: -webkit-linear-gradient(360deg, #0264ff, #17e884);
    transition: 0.3s all ease-in-out;
  }

  .new_box_main {
    padding: 15px;
    border-radius: 32px;
    min-height: 450px;
  }

  .new_box_border {
    border-radius: 32px;
    padding: 1.5px;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .new_box_main img {
    margin-bottom: 0px;
    border-radius: 32px;
    height: auto;
  }
  .news_section_main .react-multi-carousel-item {
    min-height: 490px;
  }
  .news_content {
    padding: 15px 0px;
  }
  .Recommended_Reads_section_main .new_box_main img {
    border-radius: 10px;
  }
  .new_box_main h3 {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .new_box_main h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .new_box_main p {
    font-size: 14px;
    line-height: 22px;
  }

  .new_read_more {
    display: block;
    top: 27px;
    right: 27px;
    opacity: 1;
  }
  .new_box_main img {
    border-radius: 32px;
  }
  .new_read_more .read_more {
    font-size: 14px;
    padding: 10px 12px;
    font-weight: 600;
    font-size: 10.0625px;
    line-height: 13px;
    padding: 10.0625px 11.5px;
  }

  .new_read_more .arrow_icon {
    font-size: 18px;
  }
  .Our_Team_section {
    padding: 80px 0;
  }
  .Our_Team_section .main_heading {
    font-family: 'Lufga500';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 36px;
  }
  .team_section_desktop {
    display: none;
  }
  .team_section_mobile {
    display: block;
    background-color: #02040c;
    padding-bottom: 80px;
    position: relative;
  }
  .team_section_mobile .team_small_box img {
    width: 100%;
  }
  .team_bg2 {
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 100px;
  }
  .team_section_mobile .team_small_box_border {
    margin-top: 0;
  }
  .Our_Team_section {
    padding-bottom: 0;
  }
  .team_section_mobile .carousel-indicators {
    bottom: -5%;
  }
  .team_section_mobile .team_small_box_border {
    margin-bottom: 24px;
  }
  .team_bg {
    display: none;
  }
  .new_form_section_main p {
    width: 100%;
    font-size: 12px;
    line-height: 22px;
  }
  .new_form_section_main h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    margin-top: 8px;
  }
  .new_form_radio_buttons label {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  .mb_48,
  .mb_24 {
    margin-bottom: 40px;
  }
  .mt_56 {
    margin-top: 0;
  }
  .form_submit_btn {
    justify-content: flex-start;
  }
  .footprint_form label {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  .did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    font-size: 9px;
  }
  .btn-group {
    display: -webkit-box;
  }
  .mt_b_34 {
    margin-bottom: 0 !important;
  }
  input[type='radio'] {
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
  }
  .new_form_radio_buttons label {
    margin-right: 20px;
  }
  .btn-group::-webkit-scrollbar {
    width: 0px;
  }
  .new_form_tabs_content_main {
    margin-top: 20px;
  }
  .form_radio_buttonsss {
    display: flex;
    flex-wrap: wrap;
  }
  .new_form_radio_buttons {
    margin-bottom: 16px;
  }
  .hero_video_section img {
    height: 44vh;
  }
  .hero_bg_images img {
    height: auto;
  }
  .battery_main_section {
    height: auto;
  }
  .battery_content_div h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .One_stop_solution_bg_img .one {
    top: 5px;
  }
  .newsroom_hero_main_section .nav-tabs .nav-item {
    margin-left: 0;
    margin-right: 0;
    min-width: auto;
  }
  .newsroom_hero_main_section .tabs_div .nav-tabs {
    justify-content: center;
  }
  .newsroom_hero_main_section .tabs_div .nav-item button {
    margin-left: 15px;
    margin-right: 15px;
  }
  .preshkit_products_box_leadership_img {
    height: auto !important;
  }
  .testi_content_div iframe {
    width: 100%;
    height: auto;
  }
  .driver_img {
    margin-top: -175px;
  }
  .new_form_section_main p {
    display: none;
  }
  .contact_side_single_col a {
    font-size: 14px;
    font-family: Manrope;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  .contact_side_single_col h5 {
    margin-bottom: 0;
  }
  .contact_side_single_col {
    margin-bottom: 12px;
  }
  .form-check-input {
    width: 16px !important;
    height: 16px !important;
  }
  .form-check-input:checked[type='checkbox'] {
    background-size: 12px 8px;
  }
  .join_form_section h4 {
    margin-bottom: 20px;
  }
  .join_form_section .form_submit_btn button {
    padding: 16px 24px;
    margin-top: 22px;
  }
  .invest_form_main_section .form_submit_btn {
    justify-content: center;
  }
  .Become_our_Partner_main_section {
    padding-bottom: 136px;
  }
  .our_footprint_main_section {
    background-image: url('../imgs/Map-Mobile.png');
    background-size: cover;
  }
  .Recommended_Reads_main_border .new_read_more .read_more {
    font-size: 10.063px;
    font-family: Lufga600;
    font-weight: 600;
    line-height: 12.938px;
    letter-spacing: 0.805px;
    padding: 10.063px 11.5px;
  }
  .Recommended_Reads_main_border .new_read_more .learn_arrow {
    width: 8px;
    height: 8px;
    margin-left: 5px;
  }
  .Recommended_Reads_main_border .new_read_more {
    top: 27px;
    right: 27px;
  }
  .partnership_single_box_content_blue_btn a {
    font-size: 14px;
    font-family: Lufga600;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1.12px;
    padding: 14px 16px;
  }
  .partnership_single_box_content_blue_btn .learn_arrow {
    width: 12px;
    height: 12px;
    margin-left: 5px;
  }
  .join_our_main_section .tabs_div .nav-tabs {
    overflow: scroll;
    width: 100%;
    padding-bottom: 3px;
  }
  .join_our_main_section .tabs_div .nav-tabs::-webkit-scrollbar {
    width: 0px;
  }
  .Partnership_Models_main_section .nav-item:nth-child(1) {
    min-width: 112px !important;
  }

  .footer_contact_btn {
    margin-top: 18px;
  }
  .team_work_main_section_main
    .team_work_main_section
    .carousel-indicators
    [data-bs-target] {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .new_form_radio_buttons:nth-child(1),
  .new_form_radio_buttons:nth-child(3) {
    min-width: 115px !important;
  }
  .One_stop_solution_section .carousel-indicators {
    bottom: -10% !important;
  }
  .team_section_mobile .carousel-indicators {
    bottom: -2%;
  }
  .logo_icon img {
    max-width: 57% !important;
    margin-top: 48px;
  }

  .box_icons_boxes {
    margin-bottom: 36px;
  }

  .box_icons_boxes h3 {
    margin-top: 18px;
  }

  .team_work_desktop_slider {
    display: none;
  }

  .team_work_mobile_slider {
    display: block;
  }
  .navbar-light .navbar-brand {
    margin-left: 10px;
  }
  .testi_text_div p {
    width: 86%;
    font-size: 12px;
  }
  .testi_diver_name_div {
    top: 147px;
  }

  .team_small_box,
  .team_small_box_border {
    height: auto;
    min-height: auto;
  }
  .team_small_box p {
    transition: 0.3s all ease-in-out;
  }
  .team_small_box p {
    display: none;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.096px;
  }
  .team_small_box_border:hover .team_small_box p {
    display: block;
  }
  .team_work_main_section .carousel-indicators {
    display: flex !important;
  }
  .custom_drop_down_btn {
    font-weight: 400 !important;
    font-size: 12px !important;
  }
  .footprint_form input {
    font-size: 12px;
  }
  .custom-tabs {
    width: 100%;
    overflow-x: scroll;
  }
  .tabs_div .nav {
    flex-wrap: nowrap !important;
  }
  .faq_main_section .tabs_div .nav-tabs {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .header_bg .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Battery_Form_main_section .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .translate_btn {
    right: 15%;
  }
  .testi_text_div p {
    width: 84%;
    font-size: 10px;
    top: 45px;
  }
  .testi_diver_name_div {
    top: 120px;
  }
  .tabs_div .nav-item button {
    margin-left: 0;
    margin-right: 0;
  }
  .team_small_box h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.09px;
    margin-bottom: 0;
  }
  .team_small_box h4 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  .testimonail_inner_main .active .test_side_single_active {
    min-width: auto;
    width: 100%;
  }
  .login_btn {
    width: 113px;
  }
  .team_work_main_section .mob_team_work {
    width: 100%;
    height: 250px;
    border-radius: 25px;
    margin-bottom: 20px;
  }
  .team_work_main_section .mob_team_work img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }
  .tech_single_box img {
    height: auto;
  }
  .tech_single_box_content p,
  .tech_single_box_content h2 {
    -webkit-line-clamp: 5;
  }
}
